import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import { isMobile } from 'react-device-detect';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Popup from 'reactjs-popup';
import Button from "@material-ui/core/Button";
import cx from "classnames";
import { TextField } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";

//images
import addCommentSVG from '../../../../../assets/images/feedback/add-comments.svg';
import commentCountSVG from '../../../../../assets/images/feedback/comments-count.svg';
import addCommentReplySVG from "../../../../../assets/images/feedback/add-comment-reply.svg"
import markAsUnresolved from "../../../../../assets/images/feedback/mark-as-unresolved-sign.svg"
import markAsResolved from "../../../../../assets/images/feedback/mark-as-resolved-tick.svg"
import _0 from "../../../../../assets/images/feedback/rating_0.svg"
import _1 from "../../../../../assets/images/feedback/rating_1.svg"
import _2 from "../../../../../assets/images/feedback/rating_2.svg"
import _3 from "../../../../../assets/images/feedback/rating_3.svg"
import _4 from "../../../../../assets/images/feedback/rating_4.svg"
import _5 from "../../../../../assets/images/feedback/rating_5.svg"
import _6 from "../../../../../assets/images/feedback/rating_6.svg"
import _7 from "../../../../../assets/images/feedback/rating_7.svg"
import _8 from "../../../../../assets/images/feedback/rating_8.svg"
import _9 from "../../../../../assets/images/feedback/rating_9.svg"
import _10 from "../../../../../assets/images/feedback/rating_10.svg"
import heart_smile from "../../../../../assets/images/feedback/heart-smile.svg"
import smile_emoji from "../../../../../assets/images/feedback/smile-emoji.svg"
import okay_emoji from "../../../../../assets/images/feedback/Okay.svg"
import bad_emoji from "../../../../../assets/images/feedback/Bad.svg"
import angry from "../../../../../assets/images/feedback/angry-emoji.svg"
import logo from "../../../../../assets/images/feedback/dummy-logo.png"
import SwtAlert from "../../../../../Utils/SwtAlert";
import {
  InterviewFeedbackStatus, CommentsResolveStatus, isV2Version, isCodingQuestionsFilled, isTheoryQuestionFilled, DashboardType, INTERVIEWER_WEB_APP, isV2TheoryProcessedResponse, isCodingV2Version, isV2CodingProcessedResponse, isResumeV2Version, isV2ResumeProcessedResponse, isHLDDesignV2Versions, isV2HldDesignProcessedResponse, isLLDDesignV2Versions, isV2LldDesignProcessedResponse, isTheoryV2Version,
  feedbackRatings,isInDepthKnowledgeV2Versions, isV2InDepthProcessedResponse} from "../../Constants/FeedbackConstants";

import "./../../feedback.scss";
import "./../../SectionsV1/Preview/preview.scss";
import TextEditor from "../TextEditor";

import { TheorySection } from '../../SectionsV2/Preview/TheorySection';
import CodingSectionV2 from '../../SectionsV2/Preview/CodingSection';
import DesignSectionV2 from '../../SectionsV2/Preview/DesignSection';
import ResumeSectionV2 from '../../SectionsV2/Preview/ResumeSection';
import InDepthSectionV2 from '../../SectionsV2/Preview/InDepthSection';

import Details from '../../SectionsV0/Preview/Details';
import FeedbackSummary from '../../SectionsV0/Preview/FeedbackSummary';

import CodingSectionV1 from '../../SectionsV1/Preview/CodingSection';
import TheorySectionV1 from '../../SectionsV1/Preview/TheorySection';
import ResumeSectionV1 from '../../SectionsV1/Preview/ResumeSection';
import { getS3Url } from "../../../../../Utils/image";
import env from "../../../../../config/env";
import { ConfirmationDialog } from "./dialog";
import CommonButton from "../../../../atoms/Button/CommonButton";

export const ratingToSVG = {
  "0": _0,
  "1": _1,
  "2": _2,
  "3": _3,
  "4": _4,
  "5": _5,
  "6": _6,
  "7": _7,
  "8": _8,
  "9": _9,
  "10": _10,
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// Preview section rendering starts here
const Preview = ({
  role,
  user,
  auth,
  setDisplayPreview,
  interviewDetails,
  resumeIntroData,
  codingQuestionData,
  theoryQuestionsData,
  feedbackSummaryData,
  handleSubmitClick,
  feedbackStatus,
  feedbackType,
  displayApproveButton,
  setApproveButtonDisplay,
  showFeedbackComments,
  feedbackComments,
  setFeedbackComments,
  addCommentReply,
  addFeedbackComment, editFeedbackComment, getFeedbackComments, rateFeedback, disableSubmitButtons,
}) => {
  const [showConfirmDialog,setShowConfirmDialog] = useState(false);
  // role and auth are required to make the API requests
  let isTheoryQuesFilled = isTheoryQuestionFilled(theoryQuestionsData);

  // To get comments for both OPS and INTERVIEWER
  const getComments = async () => {
    if (role !== DashboardType.CLIENT) {
      try {
        let dataResponse = await getFeedbackComments(interviewDetails?.id);
        setFeedbackComments(dataResponse);
      }
      catch (err) {
      }
    }
  };

  // To add comments by OPS
  const addComment = async (payload) => {
    try {
      let dataResponse = await addFeedbackComment(payload);
      SwtAlert(dataResponse, 1500, null, null);
      getComments();
    } catch (err) {
      console.log(err);
    }
  };

  const editComment = async (data) => {
    console.log("thi triggered", data);
    const payload = {
      feedback_comment_id: data?.id,
      resolved_status: data?.status === CommentsResolveStatus.UNRESOLVED ? CommentsResolveStatus.RESOLVED : CommentsResolveStatus.UNRESOLVED
    }
    try {
      let dataResponse = await editFeedbackComment(payload, data?.id);
      SwtAlert(dataResponse, 1500, null, null);
      getComments();
    } catch (err) {

    }
  };

  const renderAddCommentSection = (section) => {
    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    const [comment, setComment] = useState("");

    const addSectionComment = () => {
      console.log("comment", comment);
      if (comment.length > 0) {
        console.log("comment2", comment);
        const payload = {
          feedback_id: interviewDetails?.id,
          section: section,
          comment: comment,
        }
        addComment(payload);
      }
      closeTooltip();
      setComment("")
    }

    return (
      <Popup ref={ref} trigger={open => (
        <div className="comment-count-style"
          class="add-comment-feedback comment-count-style"
          style={{ background: `url(${addCommentSVG})`, backgroundRepeat: "no-repeat" }}
        />)}
        position="bottom right"
      >
        <div className="p-15">
          <Typography className="suggest-edit-dialog-title">Suggest edit</Typography>
          <TextField
            key={`add-comments`}
            id="outlined-size-small"
            size="small"
            variant="outlined"
            placeholder="Type here.."
            name="comments"
            rows={2}
            value={comment}
            onChange={(e) => { setComment(e.target.value) }}
            type="textarea"
            multiline
            rowsMax={5}
            className="comment-popup-row-style"
          />

          <Box display='flex' justifyContent='end' className="comment-popup-row-style">
            <button onClick={closeTooltip} className='white-bg-button'>
              Cancel
            </button>
            <button onClick={addSectionComment} className='blue-bg-button ml-10'>
              Enter
            </button>
          </Box>
        </div>
      </Popup>
    )
  }

  const renderAddReplySection = (parentCommentData, reply, setReply, ref) => {
    const closeTooltip = () => ref.current.close();

    const addSectionCommentReply = () => {
      if (reply !== "") {
        const payload = {
          feedback_comment_id: parentCommentData?.id,
          feedback_reply: reply,
        }
        addCommentReply(payload);
      }
      closeTooltip();
      setReply("")
    }

    return (
      <Popup ref={ref} trigger={open => (
        <div className="comments-button-background pointer">
          <img src={addCommentReplySVG} className="add-comment-reply" />
        </div>)}
        position="bottom right"
      >
        <div className="p-15">
          <Typography className="suggest-edit-dialog-title">Add Reply</Typography>
          <TextField
            key={`add-reply`}
            id="outlined-size-small"
            size="small"
            variant="outlined"
            placeholder="Type here.."
            name="reply"
            rows={2}
            value={reply}
            onChange={(e) => { setReply(e.target.value) }}
            type="textarea"
            multiline
            rowsMax={5}
            className="comment-popup-row-style"
          />

          <Box display='flex' justifyContent='end' className="comment-popup-row-style">
            <button onClick={closeTooltip} className='white-bg-button'>
              Cancel
            </button>
            <button onClick={addSectionCommentReply} className='blue-bg-button ml-10'>
              Enter
            </button>
          </Box>
        </div>
      </Popup>
    )
  }
  const renderViewUnresolvedCommentSection = (section) => {
    const [showReply, setReplyVisibility] = useState([]);
    const [reply, setReply] = useState("");
    const ref = useRef();
    const refChild = useRef();

    const setRepliesVisibility = (index) => {
      const showReplyData = [...showReply];
      showReplyData[index] = !showReply[index];
      setReplyVisibility(showReplyData);
    }

    return (
      <>
        {/* <p>hello</p> */}
        {feedbackComments?.filter((data) => (data?.section === section) && data?.status === CommentsResolveStatus.UNRESOLVED)?.length > 0 &&
          <Popup trigger={open => (
            <div className="comment-count-style"
              style={{ background: `url(${commentCountSVG})`, backgroundRepeat: "no-repeat" }}
            >
              {feedbackComments?.filter((data) => (data?.section === section) && data?.status === CommentsResolveStatus.UNRESOLVED)?.length}
            </div>)}
            position="bottom right"
            nested={true}
          //            style={{height:"300px", width:"300px"}}
          >
            {feedbackComments?.filter((data) => (data?.section === section) && data?.status === CommentsResolveStatus.UNRESOLVED)?.map((list, index) => {
              const date = moment.unix(list?.created_at).format("MMM DD, hh:mm a");;
              return (
                <>
                  <Box display="flex" flexDirection="column" className={`padding-comment-box ${list?.status === CommentsResolveStatus.UNRESOLVED ? "unresolved-bg" : "resolved-bg"}`}>
                    <div className='side-drawer-date'>{date}</div>
                    <div className='side-drawer-date mt-10'>{list?.created_by_name} {list?.created_by_profile && <span>{" ("}{list?.created_by_profile?.replaceAll('_', ' ')}{")"}</span>}</div>
                    <div className="side-drawer-comments mt-10"> {list?.comment}  </div>
                    <Box display="flex" className="mt-10" justifyContent="space-between" alignContent="center">
                      <Box display="flex">
                        <Tooltip title={list?.status === CommentsResolveStatus.UNRESOLVED ? "Mark as Resolved" : "Mark as Unresolved"}>
                          <div className="comments-button-background mr-1 pointer" onClick={(e) => editComment(list)}>
                            <img src={list?.status === CommentsResolveStatus.UNRESOLVED ? markAsResolved : markAsUnresolved} style={{ alignSelf: "center", width: "100%", height: "100%", padding: "5px" }} />
                          </div>
                        </Tooltip>
                        {list?.status === CommentsResolveStatus.UNRESOLVED && renderAddReplySection(list, reply, setReply, refChild)}
                      </Box>
                      {list?.feedback_thread?.length > 0 &&
                        <div style={{ alignSelf: "center" }} className="side-drawer-show-hide-replies">
                          <span className={cx('pointer', { 'mark-as-style-resolved': true })}
                            onClick={(e) => setRepliesVisibility(index)}>
                            {showReply[index] ? "Hide" : "Show"}{" Replies ("}{list?.feedback_thread?.length}{")"}
                          </span>
                        </div>
                      }
                    </Box>
                  </Box>
                  {showReply[index] && list?.feedback_thread?.map((replyList) => {
                    const date = moment.unix(replyList?.created_timestamp).format("MMM DD, hh:mm a");;
                    return (
                      <Box display="flex">
                        <div className="vertical-reply-divider vertical-line-comment-margins" />
                        <Box display="flex" flexDirection="column" className={`m-5-10 padding-comment-box comment-reply-bg w-100`}>
                        <div className='side-drawer-date'>{date}</div>
                        <div className='side-drawer-date mt-10'>{replyList?.created_by_name} {replyList?.created_by_profile && <span>{" ("}{replyList?.created_by_profile?.replaceAll('_', ' ')}{")"}</span>}</div>
                          <div className="side-drawer-comments mt-10"> {replyList?.text}  </div>
                        </Box>
                      </Box>
                    )
                  })}
                </>)
            })}
          </Popup>
        }
      </>
    )
  }
  const handleEditNow = () => {
      setShowConfirmDialog(!showConfirmDialog);
  }
  // Conditional Rendering for OPS,CLIENT & INTERVIEWER Preview 
  return (role === DashboardType.OPS ?
    <>

      {feedbackStatus === InterviewFeedbackStatus.APPROVED &&
        user?.roles["SUPER_ADMIN"] !== undefined &&
        user?.roles["SUPER_ADMIN"] != null && (
          <button className={`previous-button`} onClick={handleEditNow}>
            EDIT NOW
          </button>
      )}
      <ConfirmationDialog isOpen={showConfirmDialog} setIsOpen={setShowConfirmDialog} setDisplayPreview={setDisplayPreview}/>
      {/* {feedbackStatus !== InterviewFeedbackStatus.APPROVED && ( */}
        <Buttons
          role={role}
          showFeedbackComments={showFeedbackComments}
          feedbackStatus={feedbackStatus}
          setDisplayPreview={setDisplayPreview}
          handleSubmitClick={handleSubmitClick}
          displayApproveButton={displayApproveButton}
          setApproveButtonDisplay={setApproveButtonDisplay}
          feedbackComments={feedbackComments}
          interviewDetails={interviewDetails}
          rateFeedback={rateFeedback}
          disableSubmitButtons={disableSubmitButtons}
        />
       {/* )} */}

      <div id="preview-element" style={{ minWidth: '920px', margin: 'auto', marginBottom: '20px' }}>
        <Details
          interviewDetails={interviewDetails}
          codingQuestionData={codingQuestionData}
          feedbackSummaryData={feedbackSummaryData}
          feedbackType={feedbackType}
          showFeedbackComments={showFeedbackComments}
          renderAddCommentSection={renderAddCommentSection}
          renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
        />

        <FeedbackSummary
          interviewDetails={interviewDetails}
          feedbackStatus={feedbackStatus}
          feedbackSummaryData={feedbackSummaryData}
          showFeedbackComments={showFeedbackComments}
          renderAddCommentSection={renderAddCommentSection}
          renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
        />
      
        {(isResumeV2Version(interviewDetails) && isV2ResumeProcessedResponse(interviewDetails))
          ?
          <ResumeSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.resume}
            showFeedbackComments={showFeedbackComments}
            renderAddCommentSection={renderAddCommentSection}
            renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
            role={role}
            user={user}
            auth={auth}
          />
          : <ResumeSectionV1
          role={role}
          interviewDetails={interviewDetails}
          resumeIntroData={resumeIntroData}
          showFeedbackComments={showFeedbackComments}
          renderAddCommentSection={renderAddCommentSection}
          renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
            user={user}
            auth={auth}
        />}

        {(isCodingV2Version(interviewDetails) && isV2CodingProcessedResponse(interviewDetails))
          &&
          <CodingSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.coding}
            showFeedbackComments={showFeedbackComments}
            renderAddCommentSection={renderAddCommentSection}
            renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
            CustomizedDialogs={CustomizedDialogs}
            feedbackType={feedbackType}
          />
        }

        { isHLDDesignV2Versions(interviewDetails) && isV2HldDesignProcessedResponse(interviewDetails)
           && <DesignSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.hld_design}
            showFeedbackComments={showFeedbackComments}
            renderAddCommentSection={renderAddCommentSection}
            renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
            CustomizedDialogs={CustomizedDialogs}
            feedbackType={feedbackType}
          />
        }

        { isLLDDesignV2Versions(interviewDetails) && isV2LldDesignProcessedResponse(interviewDetails)
           && <DesignSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.lld_design}
            showFeedbackComments={showFeedbackComments}
            renderAddCommentSection={renderAddCommentSection}
            renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
            CustomizedDialogs={CustomizedDialogs}
            feedbackType={feedbackType}
          />
        }

        { isInDepthKnowledgeV2Versions(interviewDetails) && isV2InDepthProcessedResponse(interviewDetails)
           && <InDepthSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.indepth_knowledge}
            showFeedbackComments={showFeedbackComments}
            renderAddCommentSection={renderAddCommentSection}
            renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
            CustomizedDialogs={CustomizedDialogs}
            feedbackType={feedbackType}
          />
        }

        {
          !(isCodingV2Version (interviewDetails) ||  isHLDDesignV2Versions(interviewDetails) ||  isLLDDesignV2Versions(interviewDetails)) &&
          isCodingQuestionsFilled(codingQuestionData, feedbackType, interviewDetails) &&
          <CodingSectionV1
            interviewDetails={interviewDetails}
            codingQuestionData={codingQuestionData}
            feedbackType={feedbackType}
            showFeedbackComments={showFeedbackComments}
            renderAddCommentSection={renderAddCommentSection}
            renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
            CustomizedDialogs={CustomizedDialogs}
          />
        }

        {(isTheoryV2Version(interviewDetails) && isV2TheoryProcessedResponse(interviewDetails))
          ?
          <TheorySection
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.theory}
            showFeedbackComments={showFeedbackComments}
            renderAddCommentSection={renderAddCommentSection}
            renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
          />

          : isTheoryQuesFilled ? <TheorySectionV1
            interviewDetails={interviewDetails}
            theoryQuestionsData={theoryQuestionsData}
            showFeedbackComments={showFeedbackComments}
            renderAddCommentSection={renderAddCommentSection}
            renderViewUnresolvedCommentSection={renderViewUnresolvedCommentSection}
          /> : <></>
        }


      </div>

      {feedbackStatus !== InterviewFeedbackStatus.APPROVED && (
        <Buttons
          role={role}
          showFeedbackComments={showFeedbackComments}
          feedbackStatus={feedbackStatus}
          setDisplayPreview={setDisplayPreview}
          handleSubmitClick={handleSubmitClick}
          displayApproveButton={displayApproveButton}
          setApproveButtonDisplay={setApproveButtonDisplay}
          feedbackComments={feedbackComments}
          interviewDetails={interviewDetails}
          rateFeedback={rateFeedback}
          disableSubmitButtons={disableSubmitButtons}
        />
      )}
    </> :
    // Non OPS Preview
    <div style={{ minWidth: '920px', margin: 'auto', marginBottom: '20px' }}>

      <Buttons
        role={role}
        feedbackStatus={feedbackStatus}
        setDisplayPreview={setDisplayPreview}
        handleSubmitClick={handleSubmitClick}
        rateFeedback={rateFeedback}
        disableSubmitButtons={disableSubmitButtons}
      />

      <Details
        interviewDetails={interviewDetails}
        codingQuestionData={codingQuestionData}
        feedbackSummaryData={feedbackSummaryData}
        feedbackType={feedbackType}
      />

      <FeedbackSummary
        interviewDetails={interviewDetails}
        feedbackStatus={feedbackStatus}
        feedbackSummaryData={feedbackSummaryData}
      />
       {(isResumeV2Version(interviewDetails) && isV2ResumeProcessedResponse(interviewDetails))
          ?
          <ResumeSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.resume}
            role={role}
            user={user}
            auth={auth}
          />
          :
      <ResumeSectionV1
        interviewDetails={interviewDetails}
        resumeIntroData={resumeIntroData}
        role={role}
            user={user}
            auth={auth}
      />
      }

      {(isCodingV2Version(interviewDetails) && isV2CodingProcessedResponse(interviewDetails))
          && <CodingSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.coding}
            CustomizedDialogs={CustomizedDialogs}
            feedbackType={feedbackType}
          />
        }
        { isHLDDesignV2Versions(interviewDetails) && isV2HldDesignProcessedResponse(interviewDetails)
           && <DesignSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.hld_design}
            CustomizedDialogs={CustomizedDialogs}
            feedbackType={feedbackType}
          />
        }
        { isLLDDesignV2Versions(interviewDetails) && isV2LldDesignProcessedResponse(interviewDetails)
           && <DesignSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.lld_design}
            CustomizedDialogs={CustomizedDialogs}
            feedbackType={feedbackType}
          />
        }
           { isInDepthKnowledgeV2Versions(interviewDetails) && isV2InDepthProcessedResponse(interviewDetails)
           && <InDepthSectionV2
            interviewDetails={interviewDetails}
            data={interviewDetails?.feedback_pages?.processed_response?.indepth_knowledge}
            CustomizedDialogs={CustomizedDialogs}
            feedbackType={feedbackType}
          />
          }
        {
          !(isCodingV2Version (interviewDetails) ||  isHLDDesignV2Versions(interviewDetails) ||  isLLDDesignV2Versions(interviewDetails)) &&
          isCodingQuestionsFilled(codingQuestionData, feedbackType, interviewDetails) 
          && <CodingSectionV1
            interviewDetails={interviewDetails}
            codingQuestionData={codingQuestionData}
            feedbackType={feedbackType}
            CustomizedDialogs={CustomizedDialogs}
          />
        }

      {(isTheoryV2Version(interviewDetails) && isV2TheoryProcessedResponse(interviewDetails))
        ? <TheorySection data={interviewDetails?.feedback_pages?.processed_response?.theory} />
        : isTheoryQuesFilled ? <TheorySectionV1 theoryQuestionsData={theoryQuestionsData} /> : <></>
      }


      {feedbackStatus !== InterviewFeedbackStatus.APPROVED && (
        <Buttons
          role={role}
          feedbackStatus={feedbackStatus}
          setDisplayPreview={setDisplayPreview}
          handleSubmitClick={handleSubmitClick}
          rateFeedback={rateFeedback}
          disableSubmitButtons={disableSubmitButtons}
        />
      )}
    </div>
  );
};

const Buttons = ({ role, feedbackStatus, setDisplayPreview, handleSubmitClick, disableSubmitButtons,
  displayApproveButton,
  setApproveButtonDisplay,
  feedbackComments,
  showFeedbackComments,
  interviewDetails,rateFeedback }) => {
  const history = useHistory();
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);


  // Conditonal rendering for set of Buttons above the preview area e.g Go back, Edit now ,etc..
  const handleApproveClick = (e) => {
    setConfirmationDialog(!openConfirmationDialog);
  };
  const handleNotifyInterviewer = (e) => {
    const interviewerPhone = (interviewDetails?.interviewer_details?.contact_number || "").replace("+", "").replace("(", "").replace(")", "").replace(" ", "");
    const message = `Hi, There are some unresolved comments for the interview feedback of candidate ${interviewDetails?.candidate_details?.name}. `
      + `Please resolve them in order to complete the process. You can directly open the feedback from ${env.apps.interviewer.baseUrl || INTERVIEWER_WEB_APP}/interview-feedback/?i_id=${interviewDetails?.interview_id}`;

    const url = `https://${isMobile ? "api" : "web"}.whatsapp.com/send?phone=${interviewerPhone}&text=${message}&app_absent=0`
    window.open(url, "blank");
  };

  const handleEditClick = (e) => {
    setDisplayPreview(false);
  };

  const handleBack = (e) => {
    if (history?.length > 1) {
      history.goBack();
    } else {
      history.push("/history");
    }
  };

  function handleLogo() {
    if (interviewDetails?.client_details?.logo !== null){
      const logoUrl = getS3Url(interviewDetails?.client_details?.logo);
      if (logoUrl){
        return logoUrl;
      }
    } else {
      return logo;
    }
  }

  const [feedbackRating, setFeedbackRating] = useState({ ratings:''});
  const [payload, setPayload] = useState({
    interview_id: '',
    ratings: '',
  })

  const handleRatings = (e) => {
    const data = e?.target.value;
    let copyFeedbackRating = {...feedbackRating};
    copyFeedbackRating.ratings = data;
    copyFeedbackRating.isError =false;
    setFeedbackRating(copyFeedbackRating);
    setPayload({
      interview_id: interviewDetails?.interview_id,
      ratings: data,
    });
  };

  const [ratingDigit, setRatingDigit] = useState();
  const [ratingpic, setRatingPic] = useState();

  const handleRatingDigit = () => {
    
    {interviewDetails?.ratings === feedbackRatings.BEST ? setRatingDigit("5") 
    : interviewDetails?.ratings === feedbackRatings.GOOD ? setRatingDigit("4")
    : interviewDetails?.ratings === feedbackRatings.OKAY ? setRatingDigit("3")
    : interviewDetails?.ratings === feedbackRatings.BAD ? setRatingDigit("2")
    : interviewDetails?.ratings === feedbackRatings.WORST ? setRatingDigit("1") : "NA" };

    {interviewDetails?.ratings === feedbackRatings.BEST ? setRatingPic(heart_smile) 
    : interviewDetails?.ratings === feedbackRatings.GOOD ? setRatingPic(smile_emoji)
    : interviewDetails?.ratings === feedbackRatings.OKAY ? setRatingPic(okay_emoji)
    : interviewDetails?.ratings === feedbackRatings.BAD ? setRatingPic(bad_emoji)
    : interviewDetails?.ratings === feedbackRatings.WORST ? setRatingPic(angry) : "" };
  };

  useEffect(() => {
    handleRatingDigit();
  },[]);

  return (
    role === DashboardType.OPS ?
      <>
        <CustomizedDialogsOps
          approveClick={handleSubmitClick}
          isDialogOpen={openConfirmationDialog}
          handleClose={handleApproveClick}
          feedbackComments={feedbackComments}
          rateFeedback={rateFeedback}
          payload={payload}
          disableSubmitButtons={disableSubmitButtons}
        />

        <Box className="df-fdc">
          {/* {feedbackStatus === InterviewFeedbackStatus.APPROVED && (
            <button className={`previous-button`} onClick={handleBack}>
              BACK
            </button>
          )} */}
      <Box className="width-100 mtb-20 df-fdr">
        <Box display="flex" alignItems="center">
          <img src={handleLogo()} alt="client logo" className="logo-style"/>
        </Box>
        <Box className="width-33 df-fdc">
        <Box className="row-style border-p-left">
          <Box className="details-box-label-value">Client Name:</Box>
            <Box className="details-box-label-value details-value">
              {interviewDetails?.client_details?.name}
            </Box>
          </Box>
          <Box className="row-style border-p-left">
          <Box className="details-box-label-value">Interviewer Name:</Box>
            <Box className="details-box-label-value details-value">
              {interviewDetails?.interviewer_details?.name}
            </Box>
          </Box>
          <Box className="row-style border-p-left">
          <Box className="details-box-label-value">Candidate Name:</Box>
            <Box className="details-box-label-value details-value">
              {interviewDetails?.candidate_details?.name}
            </Box>
          </Box>
        </Box>
        {feedbackStatus !== InterviewFeedbackStatus.APPROVED && (
        <Box className="width-50 df-fdc">
        <Box className="border-p-left">  
          <Box className="overall-recommendation-heading">Rate Feedback</Box>
          <Box className="details-box-label-value width-75">Rate the quality of feedback filled by the interviewer</Box>
          <Box className="df-fdr">
            <img 
                src={angry} alt="angry" className="angry-style"
            />
            <Box className="rate-feedback">
            <Box className="ratings-style"><input type="radio" name="rating" value={feedbackRatings?.WORST} onChange={handleRatings}/>Worst</Box>
            <Box className="ratings-style"><input type="radio" name="rating" value={feedbackRatings?.BAD} onChange={handleRatings}/>Bad</Box>
            <Box className="ratings-style"><input type="radio" name="rating" value={feedbackRatings?.OKAY} onChange={handleRatings}/>Okay</Box>
            <Box className="ratings-style"><input type="radio" name="rating" value={feedbackRatings?.GOOD} onChange={handleRatings}/>Good</Box>
            <Box className="ratings-style"><input type="radio" name="rating" value={feedbackRatings?.BEST} onChange={handleRatings}/>Best</Box>
            </Box>
            <img 
                src={heart_smile} alt="smile" className="smile-style"
            />
          </Box>
        </Box>
        </Box>
        )}
        {feedbackStatus === InterviewFeedbackStatus.APPROVED && interviewDetails?.ratings !== null &&(
        <Box  className="feedback-rating-box" >
          <Box className="overall-recommendation-heading">Feedback Quality Rating</Box>
          <Box className="feedback-rating-bg">
            <Box>
              <Box className="rating-style"> {interviewDetails?.ratings} </Box>
              <Box className="rating-digit-style">
                {ratingDigit} out of 5
              </Box>
            </Box>
            <Box>
              <img src={ratingpic} alt="emoji" className="emoji-style" />
            </Box>
          </Box>
        </Box>
        )}
      </Box>
      
          {feedbackStatus !== InterviewFeedbackStatus.APPROVED && (
            <Box className="width-100 mtb-20 df-fdr jc-sb">
              <button className={`previous-button`} onClick={handleEditClick}>
                EDIT NOW
              </button>

              <div>
                {showFeedbackComments && (
                  <button className="previous-button mr-10" disabled={feedbackComments?.filter((data => data?.status === CommentsResolveStatus.UNRESOLVED)).length > 0 ? false : true} onClick={handleNotifyInterviewer}>
                    NOTIFY INTERVIEWER
                  </button>
                )}
                {displayApproveButton && (
                  <button className="next-button" onClick={handleApproveClick}>
                    APPROVE
                  </button>
                )}

                {!displayApproveButton && (
                  <button className="next-button" onClick={handleSubmitClick}>
                    SUBMIT CHANGES
                  </button>
                )}
              </div>
            </Box>
          )}
        </Box>
      </> : <Box display="flex" justifyContent="space-between">
        {role === DashboardType.INTERVIEWER && feedbackStatus === InterviewFeedbackStatus.APPROVED && (
          <button className={`previous-button`} onClick={handleBack}>
            BACK
          </button>
        )}
        {role === DashboardType.INTERVIEWER && feedbackStatus !== InterviewFeedbackStatus.APPROVED && (
          <>
            <button className={`previous-button`} onClick={handleEditClick}>
              BACK TO EDIT
            </button>

            <CommonButton
              buttonName={"Submit"}
              styleClassName={"next-button"}
              onClick={handleSubmitClick}
              isDisable={disableSubmitButtons}
            />

          </>
        )}
      </Box>
  );
};

// CustomizedDialogOps and CustomizedDialogs were same named components. Ops opens the approve dialog box while the default just opens the coding questions check dialog
function CustomizedDialogsOps({ approveClick, isDialogOpen, handleClose, feedbackComments, rateFeedback, payload, disableSubmitButtons }) {
  const handleApprove = (e) => {
    approveClick(e);
    if(payload?.interview_id && payload.ratings && payload.ratings?.length>0)
    {
      rateFeedback(payload);
    }
  }
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Box display="flex">
            <div className="dialog-title">Approve This Feedback?</div>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {" "}
          <Typography gutterBottom>
            <div className="dialog-content mb-20">
              Are you sure you want to approve this feedback?
            </div>{" "}
          </Typography>
          {feedbackComments?.filter((data => data?.status === CommentsResolveStatus.UNRESOLVED)).length > 0 &&
            <Typography gutterBottom>
              <div className="dialog-content mb-20">
                You still have {feedbackComments?.filter((data => data?.status === CommentsResolveStatus.UNRESOLVED)).length} unresolved comments. Please resolve them before you approve.
              </div>{" "}
            </Typography>
          }
          <Typography gutterBottom>
            <div className="dialog-content mb-20">
              Once you approve, this feedback will be visible to clients and interview can be marked as Completed from the dashboard.
            </div>{" "}
          </Typography>
        </DialogContent>

        <DialogActions className="approve-action-btns">
          <Box onClick={handleClose} className="approve-cancel-btn">
            Cancel
          </Box>
          <CommonButton 
             buttonName={"Yes, Approve"}
             onClick={handleApprove}
             isDisable={disableSubmitButtons}
             styleClassName={'feedback-approveBtn'}
           />
        </DialogActions>
      </Dialog>
    </div>
  );
}
export function CustomizedDialogs({ isDialogOpen, handleClose, solutionCodeData }) {
  const setCodeTextValue = (value) => {
  }
  return (
    <div>
      <Dialog fullWidth={true}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className="dialog-title">SOLUTION CODE</div>
          <div className="dialog-content" style={{ fontSize: "13px", marginTop: "8px" }}>CODING QUESTIONS -  <b>Question {solutionCodeData?.index === null ? "" : (solutionCodeData?.index + 1)}</b></div>
        </DialogTitle>

        <DialogContent dividers>

          <TextEditor
            value={solutionCodeData?.questionData?.solutionCode}
            setValue={setCodeTextValue}
            readOnly={true}
          />

        </DialogContent>

      </Dialog>
    </div>
  );
}
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


export default Preview;
