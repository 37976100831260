export const FormAction = {
    ADD: "ADD",
    EDIT: "EDIT",
    DUPLICATE : "DUPLICATE"
};

export const StepperSteps = {
    TEMPLATES: { 
        key: 'TEMPLATES', 
        label:'Select Template',
        visitedIcon: '../../assets/images/guide/template-visited.svg',
        unvisitedIcon: '../../assets/images/guide/template-unvisited.svg',
    },
    GUIDELINES_INTRODUCTION: { 
        key: 'GUIDELINES_INTRODUCTION', 
        label: 'Guidelines and Intro',
        visitedIcon: '../../assets/images/guide/guidelines-visited.svg',
        unvisitedIcon: '../../assets/images/guide/guidelines-unvisited.svg',
    },
    TECHNICAL: { 
        key: 'TECHNICAL', 
        label: 'Technical',
        visitedIcon: '../../assets/images/guide/technical-visited.svg',
        unvisitedIcon: '../../assets/images/guide/technical-unvisited.svg',
     },
    THEORETICAL: { 
        key: 'THEORETICAL',
        label: 'Theoretical',
        visitedIcon: '../../assets/images/guide/theory-visited.svg',
        unvisitedIcon: '../../assets/images/guide/theory-unvisited.svg',
    },
    INDEPTH_KNOWLEDGE: {
        key: 'INDEPTH_KNOWLEDGE', 
        label: 'InDepth Knowledge',
        visitedIcon: '../../assets/images/guide/indepth-visited.svg',
        unvisitedIcon: '../../assets/images/guide/indepth-unvisited.svg',
    }
}

export const StagesType = {
    TEMPLATES: "TEMPLATES",
    GUIDELINES_INTRODUCTION : "GUIDELINES_INTRODUCTION",
    TECHNICAL : "TECHNICAL",
    THEORETICAL : "THEORETICAL",
    INDEPTH_KNOWLEDGE: "INDEPTH_KNOWLEDGE"
}

export const GuidelineIntroductionType = {
    GENERIC_INSTRUCTIONS : "GENERIC_INSTRUCTIONS",
    RATINGS : "RATINGS",
    TIME_DISTRIBUTION : "TIME_DISTRIBUTION",
    SKILL_EVALUATION : "SKILL_EVALUATION",
    RESUME : "RESUME",
}

export const TechnicalType = {
    TECHNICAL_QUESTIONS_DISTRIBUTION : "TECHNICAL_QUESTIONS_DISTRIBUTION",
    TECH_STACK : "TECH_STACK",
    TECHNICAL_QUESTIONS : "TECHNICAL_QUESTIONS",
    SECTION_FEEDBACK : "SECTION_FEEDBACK",
}

export const TheoreticalType = {
    THEORETICAL_QUESTIONS_DISTRIBUTION : "THEORETICAL_QUESTIONS_DISTRIBUTION",
    THEORETICAL_QUESTIONS : "THEORETICAL_QUESTIONS",
}

export const InDepthKnowledgeType = {
    INDEPTH_KNOWLEDGE_QUESTIONS_DISTRIBUTION : "INDEPTH_KNOWLEDGE_QUESTIONS_DISTRIBUTION",
    INDEPTH_KNOWLEDGE_QUESTIONS : "INDEPTH_KNOWLEDGE_QUESTIONS",
}

export const CommentStatus = {
    UNRESOLVED:"UNRESOLVED",
    RESOLVED:"RESOLVED",
    DELETED:"DELETED",
}

export const GuideSectionCssClass = {
    INITIAL : "main-box",
    NEW_COMMENT_HOVER : 'blue-box',
    PREV_COMMENT_HOVER: "light-yellow-box",
    PREV_COMMENT_CLICK : "dark-yellow-box",
}
export const GuideCommentIconClass = {
    NEW_COMMENT : "blue-icon",
    PREV_COMMENT : "yellow-icon",
}

export const sectionTypes = {
    TIME_DISTRIBUTION: 'TIME_DISTRIBUTION',
    RATINGS: 'RATINGS'
}

export const resetExternalQuestion = () => {
    return JSON.parse(JSON.stringify({
      role: [],
      tags: [],
      type: null,
      topic: null,
      max_yoe: null,
      min_yoe: null,
      category: [],
      question: {
              link: "",
              name: "",
              statement: "",
              image_link: ""
          },
        solution: {
        solution1: {
            code: "",
            link: null,
            name: null,
            approach: "",
            time_complexity: "",
            space_complexity: null
        }
    },
      tech_stack:[],
      avg_time_taken:null,
      things_to_check:[""],
      difficulty_level:null,
      follow_up_questions:null,
    }));
  };
export const RADIO_BUTTON = 'radioGroup' 

export const GUIDE_APPROVAL_STATUS = {
     PENDING: 'PENDING',
     NOT_SENT: 'NOT SENT FOR APPROVAL',
     REJECTED: 'REJECTED',
     APPROVED: 'APPROVED'
}

export const CommentCssClass = {
        LIGHT_YELLOW_BG: 'light_yellow_bg',
        DARK_YELLOW_BG: 'dark_yellow_bg'
}

export const INTERVIEW_TYPE = {
    TYPE_1: "type_1",
    TYPE_2: "type_2",
    TYPE_3: "type_3",
}

export const INTERVIEW_STRUCTURE = [
    {label:"100% Technical",value:"type_1", isSelected:false},
    {label:"75% Technical - 25% Theoretical",value:"type_2", isSelected:false},
    {label:"50% Technical - 50% Theoretical",value:"type_3", isSelected:false},
];

export const experienceLevelOption = [
    { label:"Select Experience", value:""},
    { label:"0-1 Year", value:"0-1 Year", min:"0", max:"1"},
    { label:"1-3 Years", value:"1-3 Years", min:"1", max:"3"},
    { label:"3-5 Year", value:"3-5 Year", min:"3", max:"5"},
    { label:"5-7 Years", value:"5-7 Years", min:"5", max:"7"},
    { label:"7-10 Years", value:"7-10 Years", min:"7", max:"10"},
    { label:"10+ Years", value:"9-11 Years", min:"10", max:null},
  ]
  
  export const totalInterviewDurations = [60, 90, 120];   


  export const INTERVIEW_DURATION_OPTIONS = [ 
    { label:"60 mins", value: 60, isSelected:false},
    { label:"90 mins", value: 90, isSelected:false},
    { label:"120 mins", value: 120, isSelected:false},
  ]

  export const GUIDE_PREVIEW_TABS = {
      GUIDELINES_INTRODUCTION: {
          label : "Guidelines",
          value: "GUIDELINES_INTRODUCTION",
          time: null,
          isVisible: true,
      },
      RESUME_DISCUSSION: {
          label : "Intro & Resume",
          value: "RESUME_DISCUSSION",
          time: null,
          isVisible: true,
      },
      TECHNICAL: {
          label : "Technical",
          value: "TECHNICAL",
          time: null,
          isVisible: true,
      },
      THEORETICAL: {
          label : "Theoretical",
          value: "THEORETICAL",
          time: null,
          isVisible: true,
      },
      INDEPTH_KNOWLEDGE: {
          label: "InDepth Knowledge",
          value: "INDEPTH_KNOWLEDGE",
          time: null,
          isVisible: false,
      }
  }
  