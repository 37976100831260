import {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Popover from "@mui/material/Popover";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from "moment";
import { Comments } from "../Components/Comments/Comments";
import approvedIcon from "../../../../assets/images/approvedIcon.svg";
import guideRejectedIcon from "../../../../assets/images/guideRejectedIcon.svg";
import quickLinkIcon from "../../../../assets/images/quick-link.svg";
import { GUIDE_APPROVAL_STATUS, GuideCommentIconClass } from "../Constants";
import { RightCommentBox } from "./CommentBox";
import 'react-quill/dist/quill.snow.css';
export const renderBulletPoint = (textClass, msg="",alignItems) => {
    return (
        <Box display="flex" alignItems={alignItems || "left"} gap="4px">
            <Typography className="dot">&#8226;</Typography>
            <Typography className={textClass}>{msg}</Typography>
        </Box>
    )
}

export const renderRightArrow = () => {
    const renderLine = () => {
        return (
            <svg fill="none" width="100%" height="8"  xmlns="http://www.w3.org/2000/svg">
                <line x1="0" y1="4" x2="100%" y2="4" stroke="#C7C7C7" stroke-width="1" stroke-linecap="round"/>
            </svg>
        )
    }

    const renderArrow = () => {
        return (
            <svg height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 1L3.6942 2.389C5.1431 3.16702 4.6741 4.60827 3.7594 5.42635L2 7" stroke="#C7C7C7" stroke-width="1" stroke-linecap="round"/>
            </svg>
        )
    }
    return (
        <Box height="8px" mt="-2px" width="100%" display="flex" alignItems="center">
            <Box width="99%">
                {renderLine()}
            </Box>
            <Box width="4px" ml="-4px">
                {renderArrow()}
            </Box>
        </Box>
    )
}

const handleDefaultClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
}

const openExternalLink = (questionData) => {
    const questionLink = questionData?.question?.link;
    if(questionLink)
        window.open(questionLink,"_blank");
}

// Render Technical Question with Accordion
export const QuestionAccordion = ({
    questionData, 
    index,
    ...props
}) => {
    const { openImageReference, openSolutionModal } = props;
    const difficultyLevel = questionData?.difficulty_level?.toUpperCase();
    const difficultyLevelCSS = questionData?.difficulty_level?.toLowerCase();
   
    return (
        <Accordion onClick={handleDefaultClick}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            >
                <Box className="single-question-summary">
                    <Box width="55%" display="flex" gap="12px">
                        <Typography className="text-1">Q{index+1}.</Typography>
                        <Box width="100%" display="flex" flexDirection="column" gap="2px">
                            <Typography width="100%" className="text-2 ql-editor quill-box" dangerouslySetInnerHTML={{__html: 
                                        questionData?.question?.name && questionData?.question?.name?.length > 0 ?
                                        questionData?.question?.name?.trim() :""}}>
                            </Typography>
                            <Box display="flex" alignItems="center" flexWrap="wrap" gap="4px">
                                {
                                   questionData?.tags && questionData?.tags?.length > 0 ?
                                   <Typography className="text-1 topic-text">{questionData?.tags?.join(", ")}</Typography>
                                   : ""
                                }
                                {
                                questionData?.stats!==undefined &&  questionData?.stats!==null ? 
                                <Typography className="occur-text">(asked <span className="bold">{questionData?.stats} times</span> in last 4 weeks)</Typography> :
                                ""}
                            </Box>
                        </Box>
                    </Box>
                    <Box width="45%" mr="2%" display="flex" flexWrap="wrap" justifyContent="flex-end" alignItems="center" gap="8px">
                        <Typography className={`text-1 ${difficultyLevelCSS}`}>{difficultyLevel || ""}</Typography>
                        {questionData?.avg_time_taken ? 
                        renderBulletPoint("common-text",`${questionData?.avg_time_taken} mins`,"center") : ""}
                        <Typography className="link-text">
                        <Box className="link-box" display="flex" alignItems="center" gap="4px">
                            <Typography className="dot">&#8226;</Typography>
                            <Typography className="text-1 link-text" onClick={()=>openSolutionModal(questionData)}>View Solution</Typography>
                        </Box>
                        </Typography>
                        {
                            questionData?.question?.link && questionData?.question?.link?.length > 0 && (
                            <Box className="link-box" display="flex" alignItems="center" gap="4px">
                                <Box className="dot">&#8226;</Box>
                                <Typography  className="text-1 link-text" onClick={()=>openExternalLink(questionData)}>Question Link <img src={quickLinkIcon}/></Typography>
                            </Box>
                            )
                        }
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box className="single-question-details">
                    {
                        questionData?.question?.image_link && questionData?.question?.image_link?.length > 0 ?
                        <Box>
                            <Button onClick={() => openImageReference(questionData?.question?.image_link)} className="image-btn">
                                <Box display="flex" alignItems="center" gap="4px">
                                    <Typography className="text-2 white-fg">Image reference</Typography>
                                    <Box>
                                        <VisibilityIcon className="white-fg icon"/>
                                    </Box>
                                </Box>
                            </Button>
                        </Box>
                        : ""
                    }
                    <Typography className="text-1 ql-editor quill-box" 
                    dangerouslySetInnerHTML={{__html: 
                        questionData?.question?.statement && questionData?.question?.statement?.length > 0 ? 
                        questionData?.question?.statement : ""}}>
                    </Typography>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

// Render Theoretical Question
export const SingleQuestion = ({
    questionData, 
    index,
    ...props
}) => {
    const { openImageReference, openSolutionModal } = props;
    const difficultyLevel = questionData?.difficulty_level?.toUpperCase();
    const difficultyLevelCSS = questionData?.difficulty_level?.toLowerCase();
    return (
            <Box onClick={handleDefaultClick} className="theory-single-ques-wrapper">
                <Box className="single-question-summary">
                    <Box width="55%" display="flex" gap="12px">
                        <Typography className="text-1">Q{index+1}.</Typography>
                        <Box width="100%" display="flex" flexDirection="column" gap="2px">
                            <Typography width="100%" className="text-2 ql-editor quill-box" dangerouslySetInnerHTML={{__html: 
                                        questionData?.question?.statement && questionData?.question?.statement?.length > 0 ?
                                        questionData?.question?.statement?.trim() :""}}></Typography>
                            <Box display="flex" gap="4px">
                                <Typography className="text-1 topic-text">{questionData?.tags?.join(", ")}</Typography>
                                {
                                questionData?.stats!==undefined &&  questionData?.stats!==null ? 
                                <Typography className="occur-text">(asked <span className="bold">{questionData?.stats} times</span> in last 4 weeks)</Typography> :
                                ""}
                            </Box>
                        </Box>
                    </Box>
                    <Box width="45%" mr="2%" display="flex" justifyContent="flex-end" alignItems="center" gap="8px">
                    <Typography className={`text-1 ${difficultyLevelCSS}`}>{difficultyLevel || ""}</Typography>
                        {questionData?.avg_time_taken ? 
                        renderBulletPoint("common-text",`${questionData?.avg_time_taken} mins`) : ""}
                        <Typography className="link-text">
                        <Box className="link-box" display="flex" gap="4px">
                            <Typography className="dot">&#8226;</Typography>
                            <Typography className="text-1 link-text" onClick={()=>openSolutionModal(questionData)}>View Answer</Typography>
                        </Box>
                        </Typography>
                        {
                            questionData?.question?.link && questionData?.question?.link?.length > 0 && (
                            <Box className="link-box" display="flex" gap="4px">
                                <Box className="dot">&#8226;</Box>
                                <Typography  className="text-1 link-text" onClick={()=>openExternalLink(questionData)}>Question Link <img src={quickLinkIcon}/></Typography>
                            </Box>
                            )
                        }
                    </Box>
                </Box>
            </Box>    
    )
}

const getQuestionUIByType = (type, question, index,props) => {
    if(type === "THEORETICAL" || type === "INDEPTH_KNOWLEDGE")
      return  (
            <SingleQuestion
                questionData={question}
                index={index}
                {...props}
            />)
    else
      return (
            <QuestionAccordion
                questionData={question} 
                index={index}
                {...props}
            />)
}

export const QuestionWithoutTopic = ({
    type, 
    questionList,
    ...props
}) => {
    return  questionList?.map((question, index) => {
          if(!question?.topic)
            return <>
                {getQuestionUIByType(type, question, index, props)}
            </>
      })
}

export const QuestionWithTopic = ({
    type, 
    questionList, 
    questionTopicData,
    ...props
}) => {
    let indexCount = 0;
 return questionTopicData?.map((questionTopic) => {
      let filterArr = questionList?.filter(e => { return e.topic === questionTopic?.key });
          return (
            <Box display="flex" flexDirection="column" gap="22px">
              {/*  Questions with their Topic */}
              {
                filterArr.length > 0 ? (
              <Box display="flex" flexDirection="column" gap="12px">
                      <Box display="flex" flexDirection="column" gap="4px">
                          <Typography className="question-topic">{questionTopic?.key} {questionTopic?.tech_stack && questionTopic?.tech_stack?.length > 0 ? <span className="tech-stack">({questionTopic?.tech_stack?.join(",")})</span> : ""}</Typography>
                          {
                              questionTopic?.value ? (
                              <Box display="flex" alignItems="center" gap="4px">
                                  <Typography className="note-chip">Note</Typography>
                                      <Typography className="ques-sub-heading">Ask not more than {questionTopic?.value} questions from this topic.</Typography> 
                              </Box>
                              ) : ""
                          }
                      </Box>
                      <Box display="flex" flexDirection="column" gap="8px">
                          {
                          questionList?.map((question, index) => {
                              if (question.topic === questionTopic?.key)
                                  return getQuestionUIByType(type, question, indexCount++, props)
                              })
                          }
                      </Box>
              </Box> 
              ) : ""
            }

              {/*  Questions without their Topic */}
              {
                questionList?.filter((question)=>(!question?.topic))?.length > 0 ?
               <Box display="flex" flexDirection="column" gap="4px">
                  <Typography className="question-topic">General Questions</Typography>
                  <Box display="flex" flexDirection="column" gap="8px">
                  {
                      <QuestionWithoutTopic
                        type={type} 
                        questionList={questionList}
                        {...props}
                      />
                  }
                  </Box>
               </Box>
                : ""
            }

              </Box>
          )  
                      
  })
}

// Guide Comments

export const CommentWrapper = ({
    isTemplate,
    isPreview,
    stageType, 
    sectionType, 
    commentData, 
    setCommentData,
    sectionCommentData,
    guideSectionCssEffect, 
    GuideSectionCssClass, 
    showNewCommentBox,
    setShowNewCommentBox,
    isProfileDialogOpen,
    setIsProfileDialogOpen,
    setGuideSectionCssEffect,
    structureCssEffect,
    children,
    guideCommentAPIS,
}) => {

    const handleDefaultCss = (section,subSection) => {
        guideSectionCssEffect[section][subSection] = GuideSectionCssClass?.INITIAL;
          setGuideSectionCssEffect({...guideSectionCssEffect}); 
    }
    
    const getDefaultCss = (initialCss, commentData) => {
        let cssName=  initialCss
          if(commentData?.length > 0) {    
                     cssName = cssName +" "+ GuideSectionCssClass?.PREV_COMMENT_HOVER
          } 
          return cssName;   
      }
    
      const handleMouseEnter = (section,subSection,length) => {
        const prevCss =  GuideSectionCssClass?.INITIAL+" "+GuideSectionCssClass.PREV_COMMENT_CLICK;
        if(guideSectionCssEffect[section][subSection]!== prevCss)
        {
          let cssName = GuideSectionCssClass?.INITIAL+" ";
            if(length>0)
            {
              cssName += GuideSectionCssClass?.PREV_COMMENT_HOVER;
            }
            else
              cssName += GuideSectionCssClass?.NEW_COMMENT_HOVER;
            guideSectionCssEffect[section][subSection] = cssName;
            setGuideSectionCssEffect({...guideSectionCssEffect});  
        }
      }
    
      const handleMouseLeave = (section,subSection) => {
        const prevCss =  GuideSectionCssClass?.INITIAL+" "+GuideSectionCssClass.PREV_COMMENT_CLICK;
        if( guideSectionCssEffect[section][subSection]!== prevCss)
        {
          handleDefaultCss(section,subSection); 
        }
      }
      
      const handleMouseClick = (section, subSection, length) => {
        if(isTemplate || isPreview)
          return;
        const prevCss =  GuideSectionCssClass?.INITIAL+" "+GuideSectionCssClass.PREV_COMMENT_CLICK;
        if(guideSectionCssEffect[section][subSection] === prevCss)
        {
          handleDefaultCss(section,subSection); 
          return;
        }
        if(!commentData?.name || commentData?.name?.length===0 || !commentData?.email || commentData?.email?.length===0)
        {
          setIsProfileDialogOpen(true);
        }
        if(length===0)
        {
          showNewCommentBox.status = true;
          
        }
        else
        {
          showNewCommentBox.status = false;
        }
        setShowNewCommentBox({...showNewCommentBox});
        const resetObj = structureCssEffect("reset");
        let cssName = GuideSectionCssClass?.INITIAL+" ";
        cssName += GuideSectionCssClass?.PREV_COMMENT_CLICK;
        resetObj[section][subSection] = cssName;
        setGuideSectionCssEffect(resetObj);  
      }
    
    const getCssClass= (length) =>{
        if(!length || length===0)
          return GuideCommentIconClass?.NEW_COMMENT
        return GuideCommentIconClass?.PREV_COMMENT;
    }
    return (
        <>
        <Box
        onMouseEnter={() => handleMouseEnter(stageType,sectionType,sectionCommentData?.length)}
        onMouseLeave={() => handleMouseLeave(stageType, sectionType)}
        onClick={() => handleMouseClick(stageType, sectionType, sectionCommentData?.length)}
        className={ getDefaultCss(guideSectionCssEffect?.[stageType]?.[sectionType],  commentData?.data?.[stageType]?.[sectionType])}
      >
      <Box width="100%">{children}</Box>
      <Box>
      <Box className="comment">
            <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() => handleDefaultCss(stageType, sectionType)}
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>getCssClass(sectionCommentData?.length) }
                  isRightBoxOpen={guideSectionCssEffect?.[stageType]?.[sectionType] === "main-box dark-yellow-box"}
                  isTemplate={isTemplate}
                  isPreview={isPreview}
                />
            </Box>
      </Box>
    </Box>
    {
        commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[stageType]?.[sectionType] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={commentData?.data?.[stageType]?.[sectionType]}
                      guideCommentAPIS={guideCommentAPIS}
                      type={stageType}
                      subType={sectionType}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : ""
    }
    </>
    )
}

export const GuideApprovalDetails = ({
    isOpsDashboard,
    isClientDashboard,
    dataPreview,
    approverHistoryCount,
    guideLatestStatus,
    isGuideLatestStatusPending,
    checkGuidePreviousHistory,
    guideApprovalPreviousStatusDetails,
    guidePreviousApproverDetails,
}) => {
    return (
    <Box>
        { ((isOpsDashboard || isClientDashboard) && ((guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED || guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED)  ||  (approverHistoryCount > 1 && (isGuideLatestStatusPending() && checkGuidePreviousHistory())))) &&
                            <div className={`guideApprovalStatusBox`}>
                                <div className={
                                    (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) ? 'approvedBox' : 
                                    (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 'rejectedBox' :
                                    guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED ? 'approvedBox' :  guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED ?'rejectedBox'  : ''
                                    }>
                                        <div className="statusTextBox">
                                            {((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved')  === GUIDE_APPROVAL_STATUS.APPROVED) ||   guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED)  && <img src={approvedIcon} className="approvedIcon"/>}
                                            <p className={`statusText ${
                                                (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved')  === GUIDE_APPROVAL_STATUS.APPROVED) ? 'whiteText' : 
                                                (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 'redText' :
                                                guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED ? 'whiteText' :  guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED  ? 'redText' : '' 
                                                }`}>
                                                {(isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) ? 'Approved' : 
                                                (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 'Rejected' :
                                                guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED ? 'Approved' :  guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED ?  'Rejected' : '' }
                                            </p>
                                            {((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ||   guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED) && <img src={guideRejectedIcon} className="rejectionIcon"/>}
                                        </div>
                                            {((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ||   guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED) &&  
                                                <p className="reasonText">
                                                {
                                                        (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 
                                                                guideApprovalPreviousStatusDetails('reason')
                                                            : 
                                                            dataPreview?.approval_history[approverHistoryCount - 1]?.reason
                                                }
                                                </p>
                                            }
                                        
                                </div>

                                <div className="detailBox">
                                            <div className="detailSubBox mt-10">
                                                <p className="detailLightText">{
                                                        (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) ? 'Approved by:' : 
                                                        (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 'Rejected by:' :
                                                        guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED ? 'Approved by:' :  guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED ?'Rejected by:'  : ''
                                                }</p>
                                                <p className="detailBoldText mt-15">
                                                {((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) || (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED)) ? 
                                                    guidePreviousApproverDetails('name') : 
                                                    dataPreview?.approver_details[approverHistoryCount - 1]?.name
                                                }
                                                </p>
                                            </div>
                                            <div className="detailSubBox mt-8">
                                                <p className="detailLightText">Date & Time:</p>
                                                <p className="detailBoldText mt-15">
                                                {
                                                    ((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) || (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED)) ?
                                                    moment.unix(guideApprovalPreviousStatusDetails('timestamp')).format("DD MMM, YYYY | hh:mm A")
                                                        :
                                                        moment.unix(dataPreview?.approval_history[approverHistoryCount - 1]?.approval_timestamp).format("DD MMM, YYYY | hh:mm A")
                                                    }
                                                </p>
                                            </div>
                                            <div className="detailSubBox mt-8">
                                                <p className="detailLightText">Guide Last Updated:</p>
                                                <p className="detailBoldText mt-15">{moment.unix(dataPreview?.last_updated_timestamp).format("DD MMM, YYYY | hh:mm A")}</p>
                                            </div>
                                </div>
                        </div>         
                    }
    </Box>)
}