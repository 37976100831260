import React, {useState, useEffect, useCallback, useRef} from 'react'
import TableDragSelect from 'react-table-drag-select'
import {PageView, HeaderView} from '../../components/Pages/Views'
import NavBar from '../Nav'
import cogoToast from 'cogo-toast'
import moment from 'moment-timezone';
import {fetchAvailability, saveAvailability, getMaxInterviewsNum, setDefaultMaxInterviewsNum, setMaxInterviewsNum} from '../../services/availability'
import { useDispatch, useSelector } from 'react-redux';
import {availabilityAction} from '../../store/actions/Availability'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CreateIcon from '@material-ui/icons/Create';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CustomModal from '../../components/Models/CustomModal';
import Button from '@material-ui/core/Button';
import{ useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './availability.scss';
import { useAvailabilityContext, useAvailabilityContextUpdate } from '../../availabilityContext'
import { profileAction } from '../../store/actions/Profile'
import useWindowDimension from "../../utilis/windowDimension"
import Fab from "@material-ui/core/Fab";
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import cx from "classnames";
import DefaultLoader from '../../components/Loader/DefaultLoader';
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2';

// Array for selecting num of max interviews
const maxNumOfInterviews = ["0","1","2","3","4","5","6","7","8","9","10"]

const calculateDaysArray = () => {
    let daysArray=[]
    for(let i=0;i<=7;i++){
        const date = new Date();
        date.setDate(date.getDate()+i);
        daysArray.push(date);
    }
    return daysArray;
}

const days = calculateDaysArray();

const Availability = (props) => {
    const { isEditing, link } = useAvailabilityContext();  
    const { changeIsEditing, changeLink } = useAvailabilityContextUpdate();
    const [isLoaderVisible, setIsLoaderVisible] = useState(false)
    const dispatch = useDispatch();
    const historyRef = useHistory();
    const fetchedAvailability = useSelector((state) => state && state.availability && state.availability && state.availability.list && state.availability.list);
    const [cells, setCells] = useState(Array(31).fill().map(_ =>  Array(9).fill(false)));
    const timeZone = useSelector((state)=> state?.user?.user_config?.timezone);
    const [seletectedTimeZone , setSeletectedTimeZone] = useState(timeZone);
    const [enableSlot , setEnableSlot] = useState(false);
    const [cellsPayload,setCellsPayload] = useState(transposeArray(Array(31).fill().map(_ =>  Array(9).fill(true))));
    const [leaveModal,setLeaveModal] = useState(false);
    const profile = useSelector((state) => state?.profile)
    const [count, setCount] = useState('0');
    const [childCounts, setChildCounts] = useState({}) // Map of "DD-MM-YYYY": "maxCount"
    const [originalChildCounts, setOriginalChildCounts] = useState({}); 
    const [countsFetched, setCountsFetched] = useState(false) // Tracks if counts have been fetched (first time only)
    const { width } = useWindowDimension()
    const [xAxisCurrentDir, setXAxisCurrentDir] = useState("left"); // State to track if user is seeing left/right of table (mobile screen only)
    const tableRef = useRef(null); // Ref to table (for scrolling)
    const [interviewerAvailability , setInterviewerAvailability] = useState(null);
    const [bookedCells, setBookedCells] = useState(Array(31).fill().map(_ =>  Array(9).fill(false)));
    const [isbookedCellData, setBookedCellData] = useState(false);
    const [isMaxCountLoaderVisible, setIsMaxCountLoaderVisible] = useState(false);
    const [mat_maxed, setMatMaxed] = useState(Array(31).fill().map(_ =>  Array(9).fill(false)));

    // Sets default count whenever profile changes
    useEffect(() => {
        if(profile && profile.max_interviews_per_day){
            setCount(profile.max_interviews_per_day.toString())
        }
    }, [profile])

    // Set child counts on load it gives you the date from current date to next 7 days date which show in the header section of the table
    useEffect(() => {
        (async () => {
            const fromDate = days[0]
            const toDate = new Date(days[days.length - 1])
            toDate.setDate(toDate.getDate() + 1)

            const res = await getMaxInterviewsNum(
                moment(fromDate).format("DD-MM-YYYY"),                
                moment(toDate).format("DD-MM-YYYY"),
            )
            if(res){
                const newChildCounts = {}
                Object.keys(res).forEach((newChildCountObj) => {
                    newChildCounts[newChildCountObj] = res[newChildCountObj].toString()
                })
                setChildCounts((prevChildCounts) => ({
                    ...prevChildCounts,
                    ...newChildCounts
                }))
                setOriginalChildCounts(JSON.parse(JSON.stringify(newChildCounts)))
            }
            setCountsFetched(true)
        })()
    }, [])
    
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if(timeZone){
            setSeletectedTimeZone(timeZone)
        }
    },[timeZone])

    
    const fetchData = async () => {
        try{
            let payload ={
                timezone:seletectedTimeZone,
            }
            const res = await fetchAvailability(payload);
            setInterviewerAvailability(res)
            dispatch(availabilityAction(res))
            const bookedSlotData = convertDataToCells(res?.booked_slots);
            const maxedSlotsData = convertDataToCells(res?.maxed_slots);
            // It generates 2-D array of true and false
            // first row and first column we are not using in the table 
            setBookedCells(transposeArray(bookedSlotData));

            // .....................................................................................................
            setMatMaxed(transposeArray(maxedSlotsData))
            // .....................................................................................................
            
            setBookedCellData(true)
            formatData(res?.available_slots)
        } catch(err){
        }
    }

    const formatData = (data) => {
        const formattedData = convertDataToCells(data);
        const transposedCells = transposeArray(formattedData) 
        setCells(transposedCells)
        setCellsPayload(transposeArray(transposedCells))
    }

    const changeCells = async (val) => {
        const transposedCells = transposeArray(val);
        if(!checkValiditiy(transposedCells.map(item => item.slice(1)))){
            cogoToast.error('Each slot should be at least one hour long')
            return;
        }
        if(!enableSlot){
            return;
        }
        setCells(val);
        setCellsPayload(transposedCells);
        const newAvailability = days.map((item, index) => {
            return getAvailability(transposedCells[index+1].slice(1), 8).map(item2 => ({
                "recursive": "true",
                "start_time": item2.start,
                "end_time": item2.end,
                "from_date":moment(item).format('YYYY-MM-DD'),
                "to_date":moment(item).format('YYYY-MM-DD')
            }))
        }).flat()
        dispatch(availabilityAction(newAvailability))
    }

    // Handles sending individual max counts
    // For dates that don't have an explicit count sent, the default is sent.
    // This is to done to ensure that changing default max count would only change
    // counts for next week, and not the currently ongoing one.
    const updateMaxInterviewsCounts = async () => {
        const datesAndCountsList = days.map((dayDate) => {
            const dateKey = moment(dayDate).format("DD-MM-YYYY")
            return {
                "date": dateKey,
                "interviews_count": childCounts[dateKey] || count
            }
        })
        if(isChildCountUpdated()){
            await setMaxInterviewsNum(datesAndCountsList)
            setOriginalChildCounts(JSON.parse(JSON.stringify(childCounts)))
        }
    }

    const isChildCountUpdated = () => {
        let isUpadted = false;
        Object.entries(originalChildCounts)?.map(([date, count])=>{
          if(childCounts[date] !== count){
            isUpadted = true;
          }
        })
        return isUpadted;
    }

    const updateAvailability = async() =>{
        setIsLoaderVisible(true);
        const updatedAvailability = days.map((item, index) => {
            return getAvailability(cellsPayload[index+1].slice(1), 8).map(item2 => ({
                "recursive": "true",
                "start_time": item2.start,
                "end_time": item2.end,
                "start_date":moment(item).format('YYYY-MM-DD'),
                "end_date":moment(item).format('YYYY-MM-DD')
            }))
        }).flat()
        const payload ={
            timezone:seletectedTimeZone,
            slots:updatedAvailability
        }
        try{
            await saveAvailability(payload);
            await fetchData();
            setIsLoaderVisible(false);
        }
        catch(error){
            setIsLoaderVisible(false);
        }
    }

    const handleSaveButtonClick = async () => {
        setMatMaxed(Array(31).fill().map(_ =>  Array(9).fill(false)));
        if(enableSlot){
            setIsLoaderVisible(true);
            try{
                await updateMaxInterviewsCounts()
                await updateAvailability();
                changeIsEditing(false);
                setIsLoaderVisible(false);
            }
            catch(error){
                setIsLoaderVisible(false);
            }
        }else{
            formatData(interviewerAvailability?.original_slots)
            changeIsEditing(true);
        }
       setEnableSlot(!enableSlot);
    }

    useEffect(()=>{
        if(isEditing){
            setLeaveModal(true);
        }else{
            setLeaveModal(false);
        }
    },[link])

    // Handles default max interviews change
    const handleChange = async (event) => {
        const newDefaultMaxInterviewsNum = event.target.value

        setIsMaxCountLoaderVisible(true);
        // Send new default max to backend, and update new profile received in reply
        const updatedProfile = await setDefaultMaxInterviewsNum(profile.last_updated_timestamp, newDefaultMaxInterviewsNum)
        dispatch(profileAction(updatedProfile));
        setIsMaxCountLoaderVisible(false)
    };

    // Handles child max interviews change
    const handleChildChange = (date, event) => {
        // Since the draggable table library does not allow the dropdown to maintain state in edit/non-edit mode, 
        // we are calling edit availability function whenever the interviewer tries to change the dropdown
        const maxInterviews = event.target.value
        setChildCounts((prevChildCounts) => ({
            ...prevChildCounts,
            [moment(date).format("DD-MM-YYYY")]: maxInterviews
        }))
    }
      
    const renderDropdownParent= (value) => {
        return(
            <Box sx={{ maxWidth: 50 }} className="parent-max-count-wrapper">
                <FormControl fullWidth>
                  <Select
                    // labelId="demo-simple-select-label"
                    className='parent-max-count'
                    id="demo-simple-select"
                    value={count}
                    label="Age"
                    onChange={handleChange}
                    variant="outlined"           
                  >
                    {maxNumOfInterviews.map((num) => (
                        <MenuItem value={num} key={num}>{num}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
            </Box>
        )
    }

    const handleDisableClick = async () => {
        Swal.fire({
            icon: 'error',
            html:'Click on <b>Edit Availability</b>,button to update selective max counts.',
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3500
          })
    }

    const renderDropdown= (date, index) => {
        return(
            <Box key={index} sx={{ maxWidth: 50 }} className={"child-max-count-wrapper"}>
                {countsFetched &&
                    <FormControl fullWidth>
                        <Select
                          // labelId="demo-simple-select-label"
                          className='child-max-count'
                          id="demo-simple-select"
                          value={childCounts[moment(date).format("DD-MM-YYYY")] || count }
                          label="Age"
                          onChange={(e) => { handleChildChange(date, e) }}
                          variant="standard"
                          onClick={!enableSlot ? handleDisableClick : ()=> {}}
                          disabled={!enableSlot}
                        >
                          {maxNumOfInterviews.map((num) => (
                              <MenuItem value={num} key={num}>{num}</MenuItem>
                          ))}
                        </Select>
                    </FormControl>
                }
            </Box>
        )
    }

    // Performs table scroll (x-axis)
    useEffect(() => {
        if(tableRef?.current){
            if(xAxisCurrentDir === 'left'){ // Scroll left
                tableRef?.current?.scrollBy({ left: -window.innerWidth, behavior: "smooth" });
            } else { // Scroll right
                tableRef?.current?.scrollBy({ left: window.innerWidth, behavior: "smooth" });
            }
        }
    }, [xAxisCurrentDir, tableRef])

    // Function to handle Y-axis FAB click
    const handleYFabClick = useCallback((direction) => {
        if (direction === 'up'){ // Scroll up
            window.scrollBy({ top: -400, behavior: "smooth" })
        } else { // Scroll down
            window.scrollBy({ top: 400, behavior: "smooth" })
        }
    }, [])

    const maxedColor = {backgroundColor:'#9C0F48',border:"0px",}
    const maxedColor2 = {}
    return (
        <div className="Availability">
        <DefaultLoader isLoading={isLoaderVisible} text="Optimizing Your Availability Slots..."/>
            <NavBar {...props} pageTitle="Availability" augmentElement={
                <Box display='flex' justifyContent="flex-end" alignItems="center">
                    <Button onClick={handleSaveButtonClick} color="primary" variant="contained">
                        {enableSlot ? `Save${width >= 375 ? " Availability": ""}`: `Edit${width >= 375 ? " Availability" : ""}`}
                    </Button>
                </Box>
            }/>
            <CustomModal
                open={leaveModal}
                onClose={()=>{setLeaveModal(false)}}
            >
                <Box className="leave-modal">
                    <Typography className="modal-description" >
                    Do you want to save your updated availability before moving to next page?
                    </Typography>
                    <Box className="modal-footer-btns">
                    <Button 
                        onClick={()=>{historyRef.push(link); changeIsEditing(false);}} 
                        className="leave-btn"
                        variant="outlined"
                        color="primary"
                    >
                        No,Thanks
                    </Button>
                    <Button 
                        onClick={async ()=>{
                            await Promise.all([
                                updateAvailability(),
                                updateMaxInterviewsCounts()
                            ])
                            historyRef.push(link);
                            changeIsEditing(false);
                        }} 
                        className="stay-btn"
                        variant="contained"
                        color="primary"
                    >
                        Save Availability
                    </Button>
                    </Box>
                </Box>
            </CustomModal>
            <PageView>
                <HeaderView title = 'Availability' OwnComp={<div className="fl-col headerComp">
                </div>}/>
                {seletectedTimeZone ? <>
                <div className="fl-row fl-sp-bw fl-item-cntr">
                    <Box>
                        <ul>
                            <li className="default-count-instruction-list-item">Interviews will only be able to schedule within the hours on your availability calendar.</li>
                            <li className="default-count-instruction-list-item">Click and drag below to select the times you are available.</li>
                            <li className="default-count-instruction-list-item">Your availability shall be optimized with 30 mins gaps between two interviews.</li>
                             <Typography variant="body1" className="default-count-helper-text">
                                For any new entry, set default maximum no. of interviews in a day be, 
                                <Box display="flex" className='max-count-parent'>
                                <span className="max-count-label-highlighted">&nbsp;Max. :</span>{renderDropdownParent()}  
                                        {isMaxCountLoaderVisible && <Box display="flex">
                                            <CircularProgress size={20} className='circularprogress' />
                                            <Typography className="progress-text">Updating default max count...</Typography>
                                        </Box>}
                                </Box>
                            </Typography>
                        </ul>
                    </Box>
                    <Box display='flex' alignSelf="flex-start">
                        <button className="btn-save" onClick ={handleSaveButtonClick}>
                            <span className="mr-2 ">{enableSlot ? 'Save Availability': 'Edit Availability'}</span>  {!enableSlot && <CreateIcon fontSize="medium" />}
                        </button>
                    </Box>
                </div>
                <div className="fl-row fl-sp-bw fl-item-cntr mb-3 table-legend">
                    <div>
                        <p className="m-reset-0 timezone-text"> Timezone: {timeZone}</p>
                    </div>
                    <div className="fl-row fl-item-cntr">
                        <p className="m-reset-0 mr-4"><span className="slot-avail mr-2"></span> Available Slots</p>
                        <p className="m-reset-0 mr-4"><span className="slot-booked mr-2"></span> Booked Slots</p>
                        <p className="m-reset-0 mr-4"><span className="slot-maxedOut mr-2"></span> Maxed-out Slots</p>
                        <p className="m-reset-0"><span className="slot-empty mr-2"></span> Not Available Slots</p>
                    </div>
                </div>

                {/* Table directional FABs */}
                {/* X-axis */}
                <Fab color="primary" className={cx("table-fab-x", xAxisCurrentDir)} onClick={(e) => { setXAxisCurrentDir((prev) => (prev === 'left' ? "right" : "left")) }} size="medium">
                    {xAxisCurrentDir === "right" ?
                        <LeftArrowIcon /> :
                        <RightArrowIcon />
                    }
                </Fab>

                {/* Y-axis */}
                <Box className="table-fab-container">
                    <Fab color="primary" onClick={(e) => {
                        handleYFabClick("up")
                    }} size="large">
                        <UpArrowIcon />
                    </Fab>

                    <Fab color="primary" onClick={(e) => {
                        handleYFabClick("down")
                    }} size="large">
                        <DownArrowIcon />
                    </Fab>
                </Box>
 
                <div className = {`card ${ enableSlot ? '' : 'card-disabled'}`}>
                    <div className = 'table-body mb-0' ref={tableRef}>
                      {isbookedCellData &&  <TableDragSelect class = "table table-sm table-nowrap" value={cells} onChange={(value) => changeCells(value)}>
                            <tr class = 'thead'>
                                <td disabled className="timezone-in-table-th">
                                    {/* Timezone information */}
                                    <Typography className="timezone-in-table">
                                        TMZ: {timeZone}
                                    </Typography>
                                </td>
                                {
                                    days.map((day, index)=>{
                                        /* Below component is rendered out by a map. Since the value for each one of this may change for a number of reasons (individual change in 'childCounts' map, 'count' value, profile, and date) the key includes a concat of all factors that needs to trigger a re-render. Removing them WILL stop re-rendering. */
                                        return <td className="date-day-max-count-wrapper" disabled key={`${day.getDate()}-${countsFetched}-${childCounts[moment(day).format('DD-MM-YYYY')]}-${count}-${enableSlot}`}>
                                            <>
                                                <Typography className="heading-date">{moment(day).format('MMM DD')}</Typography>
                                                <Typography className="heading-day">{moment(day).format('ddd')}</Typography>
                                                <Box className="max-count-wrapper">
                                                    <Typography className="max-count-text">Max:</Typography>
                                                    {renderDropdown(day, index)}
                                                </Box>
                                            </>
                                        </td>
                                    })
                                }
                            </tr>
                            {cells.slice(1).map((item, index) => (
                                <tr class = "py-1 availability-row">
                                  {/* this column gives me the time fro 8:AM */}
                                    <td class ="tr-pad availability-time" disabled>{formatTableTime(index+16)}</td>
                                    {
                                        item.slice(1)?.map((item2, index2)=> {
                                            return( <td disabled={bookedCells.slice(1)[index][index2+1]} style={mat_maxed[index+1][index2+1]?maxedColor:maxedColor2} className={`${bookedCells.slice(1)[index][index2+1] ? 'cell-disable tr-pad' : 'tr-pad' }`}/>
                                      )})
                                    }
                                </tr>
                            ))}
                        </TableDragSelect>}
                    </div>
                </div>
                </> :
                    <div className="mt-7">
                        <ErrorOutlineIcon className="horizontal-center font-size-100" />
                        <p className="text-center font-size-lg">Select your timezone from personal info to update your availability.</p>
                    </div>
                }
            </PageView> 
        </div>   
    )
}

export default Availability




const formatTableTime = (index) => {
    if(index === 24) return '12:00 PM'
    if(index === 25) return '12:30 PM'
    if(index > 24){
        if(index%2 === 0) return formatNumber((index/2) - 12) + ':00 PM'
        else return formatNumber((index/2-0.5)-12) + ':30 PM'
    } else{
        if(index%2 === 0) return formatNumber(index/2)  + ':00 AM'
        else return formatNumber(index/2-0.5) + ':30 AM'
    }
}

const formatNumber = (num) => num < 10 ? '0' + num : num




const checkValiditiy = (val) => {
    for(let i=0; i<val.length; i++){
        const string = val[i].join('-')
        if(string.includes('false-true-false')) return false;
        if(val[i][0] && !val[i][1]) return false;
        if(!val[i][28] && val[i][29]) return false;
    }
    return true;
}



export const getAvailability = (array, start) => {
    let temp = []
    for(let i=0; i<array.length; i++){
        if(array[i]){
            if(temp.length === 0){
                temp.push(1)
            } else{
                if(temp[temp.length-1] <0){
                    temp.push(1)
                } else{
                    temp[temp.length-1] = temp[temp.length-1] + 1
                }
            }
        } else{
            if(temp.length === 0){
                temp.push(-1)
            } else{
                if(temp[temp.length-1] > 0){
                    temp.push(-1)
                } else{
                    temp[temp.length-1] = temp[temp.length-1] - 1
                }
            }
        }
    }
    const time = []
    let start1 = start*2
    for(let i=0; i<temp.length; i++){
        if(temp[i] > 0){
            time.push({start: formatTime(start1), end: formatTime(start1+temp[i])})
            start1 = start1+temp[i]
        } else{
            start1 = start1 + (temp[i]*-1)
        }
    }
    return time

}


const formatTime = (index) => {
    if(index%2 === 0) return formatNumber(index/2)  + ':00'
    else return formatNumber(index/2-0.5) + ':30'
}




const timeToIndex = (time) => {
    const a1 = parseInt(time.slice(0, 2))
    const a2 = parseInt(time.slice(3, 5)) === 30 ? 1 : 0
    const index = (a1*2 + a2) - 16
    return index
}


const convertIntoArray = (data) => {
    const temp1 = Array(30).fill(false);
    for(let i=0; i<data.length; i++){
        for(let j=data[i].startIndex; j<data[i].endIndex; j++){
            temp1[j] = true
        }
    }
    return [false, ...temp1];
}


const convertDataToCells = (data) => {
    const firstLevelData = days
        .map(item2 => data.filter(item => {
            return item.start_date === moment(item2).format('DD-MM-YYYY')
        })
        .map(item => ({
            startIndex: timeToIndex(item.start_time), 
            endIndex: timeToIndex(item.end_time)
        }))
        .sort((a, b) => a.startIndex - b.startIndex));

    const secondLevelDat = firstLevelData.map(item => convertIntoArray(item));
    return ([Array(31).fill(false), ...secondLevelDat])
    
}


const transposeArray = (data) => data[0].map((_, colIndex) => data.map(row => row[colIndex]));