import axios from "./axios";
import moment from 'moment'

export const fetchRequests = async (user) => {
    try{
        const resp = await axios.get('/interview/unscheduled', user);
        return resp.data.filter(item => {
            if(item.interview_details && item.interview_details.preferred_slots_json){
                if (item.interview_details.preferred_slots_json.filter(item => item.start && moment(item.start*1000).isValid()  && ( new Date().setHours(0,0,0) < item.start*1000) ).length > 0) return true;
                else return false;
            }
            else return false;
        }).map(item => ({
            ...item,
            candidateName: item.candidate_name.name,
            companyName: item.company_name.client_name,
            positionName: item.position.position_name,
            roundName: item.round.name,
            duration: item.round.duration,
            dateTime: {value: item.interview_details.preferred_slots_json[0].start, label: item.interview_details.preferred_slots_json.map(item => {
                return  (item.start)? item.start:"";
            })},
            id: item.candidate_name.id,
            isUrgent: checkIfUrgent(item.interview_details.preferred_slots_json),
            isReviewd: true,
            interview_details : {
                ...item.interview_details ,
                preferred_slots_json : item.interview_details.preferred_slots_json.filter(item => item.start && moment(item.start*1000).isValid()  && ( new Date().setHours(0,0,0) < item.start*1000) )
            },
        }));
    } catch(err){

        throw err;
    }
};





export const confirmRequest = async (data) => {
    try{
        const resp = await axios.post('/schedule-interview', data);
        return {
            ...resp.data,
            candidateName: resp.data.candidate_details.name,
            companyName: resp.data.client_details.client_name,
            positionName: resp.data.position_details.position_name,
            roundName: resp.data.round_details.name,
            date: moment(new Date(resp.data.interview_details.scheduled_start_timestamp * 1000)).format('DD-MM-YY'),
            time: moment(new Date(resp.data.interview_details.scheduled_start_timestamp * 1000)).format('hh:mm a'),
            dateTime: moment(new Date(resp.data.interview_details.scheduled_start_timestamp * 1000)).format('MMM DD, YYYY | hh:mm a'),
            id: resp.data.interview_details.id,
            isUrgent: true,
            isReviewd: true,
        };
    } catch(err){
        throw err
    }
};




const checkIfUrgent = (date_times) => {
    const datesArray = date_times.map(item => {
        if(item.start) return item.start*1000
        else return ''
    }).filter(item => item !== '').sort((a, b) => a-b)
    if(moment(datesArray[0]).diff(moment(), 'days') < 1) return true;
    else return false;

}
