import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from "@mui/material/Divider";
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import queryString from "query-string";
import jwt_decode from 'jwt-decode';
import moment from "moment";
import cogoToast from "cogo-toast";
import ivLogo from "../../../../assets/images/iv-logo-white.svg";
import copyIcon from "../../../../assets/images/copy.svg";
import { GUIDE_APPROVAL_STATUS, GUIDE_PREVIEW_TABS, GuideCommentIconClass, GuidelineIntroductionType, GuideSectionCssClass, InDepthKnowledgeType, StagesType, TechnicalType, TheoreticalType } from "../Constants";
import GuidelineSection from "./section/GuidelineSection";
import ResumeSection from "./section/ResumeSection";
import TechnicalSection from "./section/TechnicalSection";
import TheoreticalSection from "./section/TheoreticalSection";
import "./GuidePreview.scss";
import { GuideApprovalDetails, renderRightArrow } from "./common";
import IndepthSection from "./section/IndepthSection";
import RejectGuideModal from "./RejectGuideModal";


function TabPanel(props) {
    const { children, value, ...other } = props;
    return (
      <div
        hidden={!value}
        {...other}
      >
        {value  && (
          <>{children}</>
        )}
      </div>
    );
  }

const GuidePreview = ({
    clientId,
    dataPreview,
    guideApprovalAPI,
    downloadFile,
    isClientDashboard,
    isOpsDashboard,
    copyLinkVisibility = false,
    getGuideShareableLinkData,
    guideCommentAPIS,
    guideCommentData,
}) => {
    /* URL params */
    const { search } = useLocation();
    const parsedQueryString = queryString.parse(search);
    let isPreview = parsedQueryString?.isPreview;
    isPreview = isPreview === "true" || isPreview === true ? true: false;
    const guide_id = parsedQueryString?.guide_id;
    const template_id = parsedQueryString?.template_id;
    const position_id = parsedQueryString?.position_id;
    const isTemplate =  !template_id || template_id?.length == 0 || template_id === 'undefined' ? false : true;

    /* Guide Data States */
    const [tabList, setTabList] = useState(GUIDE_PREVIEW_TABS);
    const [selectedTab, setSelectedTab] = useState(GUIDE_PREVIEW_TABS.GUIDELINES_INTRODUCTION);
    const [isIndepthKnowledge, setIsIndepthKnowledge] = useState(false);
    const [guideData, setGuideData] = useState({
        [StagesType.GUIDELINES_INTRODUCTION]: null,
        [StagesType.TECHNICAL]: null,
        [StagesType.THEORETICAL]: null,
        [StagesType.GUIDELINES_INTRODUCTION]: null,
    });
    const [interviewEvaluation , setInterviewEvaluation] = useState({
        HIGH: null,
        MEDIUM: null,
        LOW: null,
    });

    const [technicalArray, setTechnicalArray] = useState([]);
    const [theoreticalArray, setTheoreticalArray] = useState([]);
    const [inDepthKnowledgeArray, setInDepthKnowledgeArray] = useState([]);
    
    const [technicalQuestionTopic, setTechnicalQuestionTopics] = useState([]);
    const [theoreticalQuestionTopics, setTheoreticalQuestionTopics] = useState([]);
    const [inDepthKnowledgeQuestionTopic, setInDepthKnowledgeQuestionTopic] = useState([]);
    const [shareLinkCopied, setShareLinkCopied] = useState({ open: false, vertical: 'top', horizontal: 'center'});
    const localStorageData = JSON.parse(localStorage.getItem("guide_commenter_details"));


    /** Guide Approval */
    const [temporaryAccessToken, setTemporaryAccessToken] =  useState(window?.sessionStorage.getItem('temporary_access_token'));
    const [decodedToken, setDecodedToken] = useState(null);
    const [openRejectGuideModal, setOpenRejectGuideModal] = useState(false);
    const [referenceState, setReferenceState] = useState(null);
    const [guideRejectionReason, setGuideRejectionReason] = useState('');
    const [isApprovalStatusBtnDisable, setIsApprovalStatusBtnDisable] = useState(true);
    const [showGuideApprovedStatus, setShowApprovedGuideStatus] = useState('');
    const approverHistoryCount = dataPreview?.approval_history?.length;
    const guideLatestStatus =  dataPreview?.approval_history?.length && dataPreview?.approval_history[dataPreview?.approval_history?.length -1]?.is_approved;
    const guidePreviousStatus =   (approverHistoryCount >  1 && guideLatestStatus === GUIDE_APPROVAL_STATUS.PENDING) ?  dataPreview?.approval_history[dataPreview?.approval_history?.length -2]?.is_approved : null
  

     /* Guide Comments */
     const [commentData,setCommentData] = useState({
        name:guideCommentData?.name && guideCommentData?.name?.length>0?guideCommentData?.name:localStorageData?.name?localStorageData?.name:"",
        email:guideCommentData?.email && guideCommentData?.email?.length>0?guideCommentData?.email:localStorageData?.email?localStorageData?.email:"",
        guide_id:guide_id,
        data:{},
        isError:{
          name:null,
          email:null,
        },
        dashboardType:guideCommentData?.dashboardType,
      })
      const [guideSectionCssEffect,setGuideSectionCssEffect] = useState({});
      const [showNewCommentBox,setShowNewCommentBox] = useState({
        status:true
      });
    const [isProfileDialogOpen,setIsProfileDialogOpen] = useState(false);
    const {getAllGuideCommentsAPI} = guideCommentAPIS;

    const renderTopicList = (tagList) => {
        return(
          <div>
            {tagList?.map((data) => {
              return(
                <Box className="topic-wrapper">
                    <Typography className="common-text"><span className="highlight">{data?.key}</span> {data?.tech_stack && data?.tech_stack?.length > 0 ? <span className="tech-stack"> ({data?.tech_stack?.join(",")})</span> : ""}</Typography>
                    <Box width="100px">
                        {renderRightArrow()}
                    </Box>
                    <Typography className="common-text">Ask <span style={{fontWeight: 600}}>{data?.value}</span>{data?.[1] != 1 ? " questions" : " question"}</Typography>
                </Box>
              )
            })}
          </div> 
        )
    }

    const getTechnicalQuestions = () => {
      const technicalData = guideData[StagesType.TECHNICAL]?.sections;
      let questionList = [];
        technicalData?.map(item => {
            if(item?.type === "TECHNICAL_QUESTIONS") {
                questionList = [...item?.default, ...item?.external];
            }
        });
      return questionList;
    }

    const getTheoreticalQuestions = () => {
      const theoreticalData = guideData[StagesType.THEORETICAL]?.sections;
      let questionList = [];
      theoreticalData?.map(item => {
          if(item?.type === "THEORETICAL_QUESTIONS") {
              questionList = [...item?.default, ...item?.external];
          }
      });
      return questionList;
    }

    const getIndepthQuestions = () => {
      const IndepthData = guideData[StagesType.INDEPTH_KNOWLEDGE]?.sections;
      let questionList = [];
      IndepthData?.map(item => {
        if(item?.type === "INDEPTH_KNOWLEDGE_QUESTIONS") {
            questionList = [...item?.default, ...item?.external];
        }
    });
      return questionList;
    }

    const getTechStackByTopic = (topicArr, questionList) => {
      return topicArr?.map((topicObj) => {
          let topic = topicObj?.key;
          if(!topicObj["tech_stack"]) 
            topicObj["tech_stack"] = [];
          
          let techStack = [];
          if(questionList && questionList?.length > 0) {
            questionList?.forEach(ques=>{
              if(ques?.topic && ques?.topic === topic) {
                const quesTechStack = ques?.tech_stack || [];
                if(typeof(quesTechStack) === "string") 
                  techStack = [...techStack, quesTechStack];
                else
                  techStack = [...techStack, ...quesTechStack];
              }
            })
          }
          //add uniquie tech stack only
          topicObj["tech_stack"] = [...new Set(techStack)]; ;
          return topicObj;
      })
    }
    
    function handleTechnicalQuestionTopic(technicalArray)  {
      const questionList = getTechnicalQuestions();
        if(Array.isArray(technicalArray) === false){
            let arr = [];
            Object.keys(technicalArray)?.map((data) => {
                arr.push({
                    key : data,
                    value : technicalArray[data],
                })
            })
            let newTechnicalArray = getTechStackByTopic(arr, questionList);
            setTechnicalQuestionTopics(newTechnicalArray);
        }else{
          let newTechnicalArray = getTechStackByTopic(technicalArray, questionList);
          setTechnicalQuestionTopics(newTechnicalArray);
        }
      }

      function handleTheoreticalQuestionTopic(theoreticalArray)  {
        const questionList = getTheoreticalQuestions();
        if(Array.isArray(theoreticalArray) === false){
            let arr = [];
            Object.keys(theoreticalArray)?.map((data) => {
                arr.push({
                    key : data,
                    value : theoreticalArray[data],
                })
            })
            let newTheoreticalArray = getTechStackByTopic(arr, questionList);
            setTheoreticalQuestionTopics(newTheoreticalArray);
        }else{
          let newTheoreticalArray = getTechStackByTopic(theoreticalArray, questionList);
          setTheoreticalQuestionTopics(newTheoreticalArray);
        }
      }
  
      function handleInDepthKnowledgeQuestionTopic(inDepthKnowledgeArray)  {
        const questionList = getIndepthQuestions();
        if(Array.isArray(inDepthKnowledgeArray) === false){
            let arr = [];
            Object.keys(inDepthKnowledgeArray)?.map((data) => {
                arr.push({
                    key : data,
                    value : inDepthKnowledgeArray[data],
                })
            })
            let newIndepthArray = getTechStackByTopic(arr, questionList);
            setInDepthKnowledgeQuestionTopic(newIndepthArray);
        }else{
          let newIndepthArray = getTechStackByTopic(inDepthKnowledgeArray, questionList);
          setInDepthKnowledgeQuestionTopic(newIndepthArray);
        }
    }  

    useEffect(() => {
        handleTechnicalQuestionTopic(technicalArray);
    },[technicalArray]);

    useEffect(() => {
        handleTheoreticalQuestionTopic(theoreticalArray);
    },[theoreticalArray]);
    
    
    useEffect(() => {
        handleInDepthKnowledgeQuestionTopic(inDepthKnowledgeArray)
    },[inDepthKnowledgeArray]);

    useEffect(() => {
        decodeJWT();
      },[temporaryAccessToken])
    
      const decodeJWT = () => {
        if(temporaryAccessToken) {
          const decodedToken = jwt_decode(temporaryAccessToken);
          setDecodedToken(decodedToken);
        }
      };


      useEffect(()=>{
        if(dataPreview) {
            let intro = "";
            let tech = "";
            let theo = "";
            let inDepth = "";
            dataPreview?.stages?.forEach(item => {
              if (item?.type === "GUIDELINES_INTRODUCTION") {
                intro = item;
              }
              else if (item?.type === "TECHNICAL") {
                tech = item;
              }
              else if (item?.type === "THEORETICAL") {
                theo = item;
              }
              else if (item?.type === "INDEPTH_KNOWLEDGE") {
                inDepth = item;
                setIsIndepthKnowledge(true);
              }
            });
            setGuideData(prev=>({...prev,
                [StagesType.GUIDELINES_INTRODUCTION]: intro,
                [StagesType.TECHNICAL]: tech,
                [StagesType.THEORETICAL]: theo,
                [StagesType.INDEPTH_KNOWLEDGE]: inDepth,
            }))
        }
    },[dataPreview]);

    const getSectionDuration = (durationData, tab) => {
        const resumeDuration = +durationData[0]?.time?.value || 0;
        const technicalDuration = +durationData[1]?.time?.value || 0;
        const theoreticalDuration = +durationData[2]?.time?.value || 0;
        const indepthDuration = +durationData[1]?.time?.value || 0;
        if(tab === "RESUME_DISCUSSION") {
            return resumeDuration;
        }
        else if(tab === "TECHNICAL") {
            return technicalDuration;
        }
        else if(tab === "THEORETICAL") {
            return theoreticalDuration;
        }
        else if(tab === "INDEPTH_KNOWLEDGE") {
            return indepthDuration;
        }
    }

    useEffect(()=>{
        if(guideData[StagesType.GUIDELINES_INTRODUCTION]) {
            guideData[StagesType.GUIDELINES_INTRODUCTION]?.sections?.map(item=>{
                if(item?.type === "SKILL_EVALUATION") {
                    let obj = {}
                    item?.values?.forEach(skillData=>{
                        if(skillData?.selected)
                            {
                                let level = skillData?.value?.toUpperCase();
                                if(!obj.hasOwnProperty(level)) {
                                    obj[level] = [skillData?.label];
                                }
                                else {
                                    obj[level]?.push(skillData?.label)
                            }
                        }
                    })
                    setInterviewEvaluation((prev=>({...prev, HIGH: obj["HIGH"], MEDIUM: obj["MEDIUM"], LOW: obj["LOW"]})));
                }
            })
        }
    },[guideData])

    useEffect(()=>{
        let tempTabs = {}
        const durationData = getInteviewSectionData();

        if(isIndepthKnowledge) {
            Object.entries(GUIDE_PREVIEW_TABS)?.map(([key, tab])=>{
                if(durationData) {
                    let duration = getSectionDuration(durationData, key);
                    if(duration)
                        tab.time = getSectionDuration(durationData, key);
                }
                if(key === "GUIDELINES_INTRODUCTION" || key === "RESUME_DISCUSSION" || key === "INDEPTH_KNOWLEDGE")
                    tab.isVisible = true;
                else
                    tab.isVisible = false;

                tempTabs[key] = tab;
            })
        }
        else 
        {
            Object.entries(GUIDE_PREVIEW_TABS)?.map(([key, tab])=>{
              if(key === "INDEPTH_KNOWLEDGE")
                tab.isVisible = false;
            else
                tab.isVisible = true;

                if(durationData) {
                    let duration = getSectionDuration(durationData, key);
                    if(duration === 0)
                      tab.isVisible = false;
                    else
                        tab.time = getSectionDuration(durationData, key);
                }
                

                tempTabs[key] = tab;
            })
        }
        setTabList(tempTabs);
    },[guideData, isIndepthKnowledge])

    useEffect(() => {
        if(dataPreview) {
            setTechnicalArray(dataPreview?.stages ?  (dataPreview?.stages[1]?.sections[0]?.values?.question_topic || []) : [])
            setTheoreticalArray(dataPreview?.stages ?  (dataPreview?.stages[2]?.sections[0]?.values?.question_topic || []) : [])
            setInDepthKnowledgeArray(dataPreview?.stages && dataPreview?.stages[1]?.type == 'INDEPTH_KNOWLEDGE' ?  (dataPreview?.stages[1]?.sections[0]?.values?.question_topic || []) : []);
        }
      }, [dataPreview]);
    
    /* Guide Shareable Link */

     // Function to write text to clipboard and update state
     const writeToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            setShareLinkCopied({ open: true, vertical: 'top', horizontal: 'center' });
          })
          .catch((error) => {
            cogoToast.error("Failed to write to clipboard: " + error?.message);
          });
      };
  
        const handleShareableLink = () => {
          // Check if the clipboard API is supported by the browser
          if (!navigator.clipboard) {
            cogoToast.error("Clipboard API not supported");
            return;
          } else {
              // Always fetch the shareable link data before copying
              getGuideShareableLinkData().then((res) => {
                const url = res?.data?.client_access_url;
                if (url) {
                  writeToClipboard(url);
                } else {
                  cogoToast.error("No client access URL found in response");
                }
              }).catch((error) => {
                cogoToast.error("Failed to fetch guide shareable link data: " + error?.message);
              });
          }
              
        };
  
    /* Guide Approval */
    const handleCloseSnackBar = (e) => {
        setShowEmptyFieldWarning('');
    }
    
      const action = (
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      );
    
      const filterGuideHistoryInfo = (data) => {
        let result = data?.approval_history?.filter((item) => item?.is_approved === GUIDE_APPROVAL_STATUS.APPROVED || item?.is_approved === GUIDE_APPROVAL_STATUS.REJECTED);
        return result;
      }
    
    
      const checkGuidePreviousHistory = () => {
          let result = filterGuideHistoryInfo(dataPreview);
          if(result?.length > 0) {
                  return true;
          } else {
                return false;
          }
      }
    
      const guideApprovalPreviousStatusDetails = (type) => {
        let result = filterGuideHistoryInfo(dataPreview);
        if(result?.length > 0) {
                   if(type === 'isApproved') {
                      return result[result?.length -1]?.is_approved;
                   } else if(type === 'reason') {
                      return result[result?.length -1]?.reason;
                   } else if( type ==='timestamp') {
                       return result[result?.length -1]?.approval_timestamp;
                   }
               
        } else {
              return GUIDE_APPROVAL_STATUS.PENDING;
        }
      }
    
    
      const guidePreviousApproverDetails = (type) => {
        let approvalDetails = dataPreview?.approval_history?.filter((item) => item?. is_approved === GUIDE_APPROVAL_STATUS.APPROVED || item?. is_approved === GUIDE_APPROVAL_STATUS.REJECTED);
        if(approvalDetails?.length > 0) {
    
                  let approverDetails = dataPreview?.approver_details?.filter((item) => item?.mail_sent_timestamp ===  approvalDetails[approvalDetails?.length -1]?.mail_sent_timestamp);
                   if(approverDetails?.length > 0 &&  type === 'name') {
                      return approverDetails[approverDetails?.length - 1]?.name;
                   }
               
        }
      } 
    
      const isGuideLatestStatusPending = () => {
               if(guideLatestStatus === GUIDE_APPROVAL_STATUS.PENDING) {
                 return true;
               } else {
                  return false;
               }
      }

    const handleGuideApproval = async (action) => {
      setIsApprovalStatusBtnDisable(true);
         let payload = {
          guide_id: parseInt(guide_id),
          client_id: clientId,
          approver_email: decodedToken?.approver_email
         }
  
         if(action === 'reject') {
               payload.approved_detail = {
                is_approved: false,
                 reason: guideRejectionReason
                }
         } else {
           payload.approved_detail = {
             is_approved: true,
             reason: null
            }
         }
  
         try {
           let response = await guideApprovalAPI(payload, guide_id, temporaryAccessToken)
              if(response?.status === 200) {
                   setOpenRejectGuideModal(false);
                   setIsApprovalStatusBtnDisable(false);
                   setShowApprovedGuideStatus(action === 'reject' ? 'Rejected' : 'Approved')
                      setTimeout(() => {
                        window.location.reload(); 
                      }, 1000)
              } 
         } catch(error) {
          setIsApprovalStatusBtnDisable(false);
              cogoToast.error(
                error?.data?.msg?.toString() || 
                error?.data?.toString() || 
                error?.toString() ||
                "Failed please try again."
              )
         }
    }  


    useEffect(() => {
        if(guideRejectionReason?.length > 0) {
            setIsApprovalStatusBtnDisable(false)
        } else {
            setIsApprovalStatusBtnDisable(true)
    }
    },[guideRejectionReason])

    // Guide Comments
    const structureCssEffect = (type) => {
        const cssEffect = {
          GUIDELINES_INTRODUCTION : {},
          TECHNICAL : {},
          THEORETICAL : {},
          INDEPTH_KNOWLEDGE: {}
        };
        for(let key in GuidelineIntroductionType)
        {
            cssEffect.GUIDELINES_INTRODUCTION[GuidelineIntroductionType[key]] = GuideSectionCssClass?.INITIAL;
        }
    
        for(let key in TechnicalType)
        {
          cssEffect.TECHNICAL[TechnicalType[key]] = GuideSectionCssClass?.INITIAL;
        }
    
        for(let key in TheoreticalType)
        {
          cssEffect.THEORETICAL[TheoreticalType[key]] = GuideSectionCssClass?.INITIAL;
        } 
        for(let key in InDepthKnowledgeType)
        {
          cssEffect.INDEPTH_KNOWLEDGE[InDepthKnowledgeType[key]] = GuideSectionCssClass?.INITIAL;
        } 
        if(type!=='reset')
        {
          setGuideSectionCssEffect(cssEffect);
        }
        return cssEffect;
      }

      const structureData = (data) => {
            const tempObj = {
              GUIDELINES_INTRODUCTION : {},
              TECHNICAL : {},
              THEORETICAL : {},
              INDEPTH_KNOWLEDGE: {}
            };
    
            for(let key in GuidelineIntroductionType)
            {
              const tempArr = data?.filter(item=>item?.section===GuidelineIntroductionType[key]) || [];
              tempObj.GUIDELINES_INTRODUCTION[GuidelineIntroductionType[key]] = tempArr;
            }
            for(let key in TechnicalType)
            {
              const tempArr = data?.filter(item=>item?.section===TechnicalType[key]) || [];
              tempObj.TECHNICAL[TechnicalType[key]] = tempArr;
            }
            for(let key in TheoreticalType)
            {
              const tempArr = data?.filter(item=>item?.section===TheoreticalType[key]) || [];
              tempObj.THEORETICAL[TheoreticalType[key]] = tempArr;
            }
            for(let key in InDepthKnowledgeType)
            {
              const tempArr = data?.filter(item=>item?.section===InDepthKnowledgeType[key]) || [];
              tempObj.INDEPTH_KNOWLEDGE[InDepthKnowledgeType[key]] = tempArr;
            }
            commentData.data = tempObj;
            setCommentData({...commentData});
      }

      const getAllGuideComments = () => {
        const token = window?.sessionStorage.getItem('temporary_access_token');
        if(!guide_id || guide_id?.length === 0 || guide_id === 'undefined')
        {
          if(!isPreview)
            cogoToast.error("There is an error while fetching guide details since guide id is not present",{position:"top-right"});
          return;
        }
        const payload = {
          guide_id: guide_id,
        }
        getAllGuideCommentsAPI(payload,token).then(({data})=>{
            if(data)
            {
              structureData(data?.data);
            }
        }).catch(error=>{
            cogoToast.error(error?.response || error?.msg || error?.data || error?.toString(),{position:"top-right"});
        })
    }

    useEffect(()=>{
        structureCssEffect();
        if(!isTemplate)
        getAllGuideComments();
    },[]);

    const renderGuideApproval = () => {
        return (
                  <div className='guideStatusParentBox'>
                <div>
                    {((isGuideLatestStatusPending() && decodedToken?.approver_email) ) && 
                                <div className="btnBox bottomButton">
                                    <Button className="rejectBtn commonBtn" onClick={() => setOpenRejectGuideModal(true)}>Reject</Button>
                                    <Button className="approveBtn commonBtn" onClick={() => handleGuideApproval('approve')}>Approve</Button>
                                </div>
                            }  
                        </div>   
                        <RejectGuideModal 
                            open={openRejectGuideModal}
                            onClose={() => {
                            setOpenRejectGuideModal(false)
                            setGuideRejectionReason('')
                            }}
                            handleGuideApproval={handleGuideApproval}
                            setGuideRejectionReason={setGuideRejectionReason}
                            disable={isApprovalStatusBtnDisable}
                        />
                        <Snackbar
                            open={(showGuideApprovedStatus === 'Rejected' || showGuideApprovedStatus === 'Approved')}
                            autoHideDuration={5000}
                            onClose={handleCloseSnackBar}
                            anchorOrigin={{
                                vertical: 'top', 
                                horizontal: 'center',
                            }}
                            style={{ top: 70, left: 850 }}

                            >
                            <SnackbarContent
                                message={`Interview Guide ${showGuideApprovedStatus}`}
                                className="emptyFieldSnackBar"
                                action={action}
                        />
                            </Snackbar>
            </div>
        )
    }

    const renderHeader = () => {
        return (
            <Box className="head-main-container">
                <Box className="logo-wrapper">
                    <img className="logo-icon" src={ivLogo} />
                </Box>
                <Typography className="title">Interview Playbook - Equally Focused on Technical and Theoretical Majorly </Typography>
                <Box>
                    {
                        !decodedToken?.approver_email && 
                        <>
                            { copyLinkVisibility && !isTemplate && !isPreview ? <div className="share-link" onClick={handleShareableLink}>
                            <img src={copyIcon} height="20px" width="20px" /> <p>Copy Link</p> </div> : "" }
                        {/* ----- Link copied popup ----*/}
                        
                        { shareLinkCopied?.open && (
                            <Snackbar  
                            anchorOrigin={{ vertical:shareLinkCopied?.vertical, horizontal:shareLinkCopied?.horizontal }} 
                            autoHideDuration={1000}
                            open={shareLinkCopied?.open} 
                            onClose={()=>{setShareLinkCopied({ ...shareLinkCopied, open: false });}} > 
                                <SnackbarContent 
                                style={{ backgroundColor:'green', marginTop:"30px", fontWeight:'600'}}
                                message={<span id="client-snackbar">✅ Link copied to clipboard</span>}/>
                            </Snackbar>
                        ) }
                    </>
                    } 
                    {renderGuideApproval()}
                </Box>
      </Box>
        )
    }
    const renderValidFields = (heading,data) => {
        if(!data)
            return <></>
        return (
            <Box className="single-box-wrapper">
                <Typography className="head-text">{heading}</Typography>
                <Typography className="data-text">{data}</Typography>
            </Box>
        )
    }

    const getInteviewSectionData = () => {
        const guideVersion = !dataPreview?.version || dataPreview?.version === "v2" ? "v2" : "v1";
        let sectionData = null;
        guideData?.[StagesType.GUIDELINES_INTRODUCTION] &&
        guideData?.[StagesType.GUIDELINES_INTRODUCTION].sections?.map(item=>{
            if(item.type === "TIME_DISTRIBUTION") {
                if(guideVersion === "v1") {
                    sectionData = item?.values;
                }
                else {
                    let found = false;
                    item?.values?.map(list=> {
                        if(list?.selected)
                        {
                            found = true;
                            sectionData = list?.values;
                        }
                    })
                    if(!found)
                        sectionData = item?.values?.[0].values;
                }
            }
        })
        return sectionData;
    }
    const renderInterviewEvaluation = () => {

        const getLevelStyle = (level) => {
            if(level === "HIGH") {
              return "high-level-chip"
            } 
            else if(level === "MEDIUM")
                return "medium-level-chip"
            else  
              return "low-level-chip";
        }

        const renderEvaluationPoints = (data, level) => {
            return (
                <Box className="inner-box-wrapper">
                    {
                        data?.map(item => {
                            return <Typography className={`text-2 chip ${getLevelStyle(level)}`}>{item}</Typography>
                        })
                    }
                </Box>
            )
        }


        return (
            <Box className="box-2">
                <Box>
                    <Typography className="head-text">Evaluation Parameters</Typography>
                    <Box className="evaluation-wrapper">
                        <Typography className="text-1">Priority:</Typography>
                        { interviewEvaluation?.HIGH && interviewEvaluation?.HIGH?.length > 0 ? 
                            <Typography className={`chip text-1 ${getLevelStyle("HIGH")}`} onClick={()=>handleEvaluation("HIGH")}>High</Typography> : "" }
                        { interviewEvaluation?.MEDIUM && interviewEvaluation?.MEDIUM?.length > 0 ?
                            <Typography className={`chip text-1 ${getLevelStyle("MEDIUM")}`}  onClick={()=>handleEvaluation("MEDIUM")}>Medium</Typography> : ""}
                        { interviewEvaluation?.LOW && interviewEvaluation?.LOW?.length > 0 ?
                            <Typography className={`chip text-1 ${getLevelStyle("LOW")}`} onClick={()=>handleEvaluation("LOW")}>Low</Typography> : "" }
                        
                    </Box>
                </Box>
                {
                    interviewEvaluation?.HIGH && interviewEvaluation?.HIGH?.length > 0 ?
                    renderEvaluationPoints(interviewEvaluation?.HIGH, "HIGH")
                    : ""
                }
                {
                    interviewEvaluation?.MEDIUM && interviewEvaluation?.MEDIUM?.length > 0 ?
                    renderEvaluationPoints(interviewEvaluation?.MEDIUM, "MEDIUM")
                    : ""
                }
                {
                    interviewEvaluation?.LOW && interviewEvaluation?.LOW?.length > 0 ?
                    renderEvaluationPoints(interviewEvaluation?.LOW, "LOW")
                    : ""
                }
        </Box>
        )
    }
    const renderGuideDetails = () => {
        const guideDetails = dataPreview;
        const minExperience = (guideDetails?.min_experience!== undefined &&  guideDetails?.min_experience!== null) ?  guideDetails?.min_experience: guideDetails?.min_yoe;
        const maxExperience =  (guideDetails?.max_experience!== undefined &&  guideDetails?.max_experience!== null) ? guideDetails?.max_experience: guideDetails?.max_yoe;
        const experience = (minExperience !== undefined && minExperience!==null)  && (maxExperience !== undefined && maxExperience!==null) ?
                             `${minExperience}-${maxExperience} yrs` : null;
        const duration = `${guideDetails?.duration || "60"} mins`;                     
        return (
            <Box className="guide-details-main-container">
                <Box className="box-1">
                    {renderValidFields("Role",guideDetails?.role)}
                    {renderValidFields("Category",guideDetails?.round_category)}
                    {renderValidFields("Experience",experience)}
                    {renderValidFields("Duration",duration)}
                </Box>
                {renderInterviewEvaluation()}
            </Box>
        )
    }

    const renderTestDetails = () => {
        const handleTabChange = (e, newValue) => {
            setSelectedTab(newValue)
        }
        const commonProps = {
            commentData,
            setCommentData,
            guideSectionCssEffect,
            GuideSectionCssClass,
            showNewCommentBox,
            setShowNewCommentBox,
            isProfileDialogOpen,
            setIsProfileDialogOpen,
            setGuideSectionCssEffect,
            structureCssEffect,
            guideCommentAPIS,
            isTemplate,
            isPreview
        }
        return (
            <Box className="test-details-main-container">
                <Box className="tabs-wrapper">
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        >
                            {
                             tabList && Object.entries(tabList)?.map(([key, tab], index)=>{
                                if(tab?.isVisible)
                                return (
                                    <Tab value={tab} label={`${tab?.label} ${tab?.time ? `(${tab?.time} mins)`: ""}`} />
                                )
                             })
                            }
                    </Tabs>
                </Box>
                <Divider/>
                <Box className="tabs-content-wrapper">
                    <TabPanel value={selectedTab === GUIDE_PREVIEW_TABS.GUIDELINES_INTRODUCTION}>
                        <GuidelineSection
                        guideData={guideData}
                        {...commonProps}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTab === GUIDE_PREVIEW_TABS.RESUME_DISCUSSION}>
                        <ResumeSection
                        guideData={guideData}
                        {...commonProps}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTab === GUIDE_PREVIEW_TABS.TECHNICAL}>
                        <TechnicalSection
                        guideData={guideData}
                        getTechnicalQuestions={getTechnicalQuestions}
                        topicList={()=>renderTopicList(technicalQuestionTopic)}
                        technicalQuestionTopic={technicalQuestionTopic}
                        downloadFile={downloadFile}
                        isIndepthKnowledge={isIndepthKnowledge}
                        {...commonProps}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTab === GUIDE_PREVIEW_TABS.THEORETICAL}>
                        <TheoreticalSection
                        guideData={guideData}
                        getTheoreticalQuestions={getTheoreticalQuestions}
                        topicList={()=>renderTopicList(theoreticalQuestionTopics)}
                        theoreticalQuestionTopics={theoreticalQuestionTopics}
                        downloadFile={downloadFile}
                        isIndepthKnowledge={isIndepthKnowledge}
                        {...commonProps}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTab === GUIDE_PREVIEW_TABS.INDEPTH_KNOWLEDGE}>
                        <IndepthSection
                        guideData={guideData}
                        getIndepthQuestions={getIndepthQuestions}
                        topicList={()=>renderTopicList(inDepthKnowledgeQuestionTopic)}
                        inDepthKnowledgeQuestionTopic={inDepthKnowledgeQuestionTopic}
                        downloadFile={downloadFile}
                        isIndepthKnowledge={isIndepthKnowledge}
                        {...commonProps}
                        />
                    </TabPanel>
                    
                </Box>
            </Box>
        )
    }

    const renderProileDetails = () => {
        return (
          <Box className="profile-main-container">
            {renderGuideDetails()}
            {renderTestDetails()}
            <GuideApprovalDetails
            isOpsDashboard={isOpsDashboard}
            isClientDashboard={isClientDashboard}
            dataPreview={dataPreview}
            guideLatestStatus={guideLatestStatus}
            approverHistoryCount={approverHistoryCount}
            isGuideLatestStatusPending={isGuideLatestStatusPending}
            checkGuidePreviousHistory={checkGuidePreviousHistory}
            guideApprovalPreviousStatusDetails={guideApprovalPreviousStatusDetails}
            guidePreviousApproverDetails={guidePreviousApproverDetails}
            />
          </Box>
        );
      };

    return <Box className="shared-guide-playbook-main-container">
            {renderHeader()}
            <Box className="body-main-container">
            {renderProileDetails()}
            </Box>
    </Box>
}
export default GuidePreview;